import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import auth from "../services/authService";

const Logout = () => {
    const [loading, setLoading] = useState(true);

    async function logOut() {
        await auth.logOut();
        window.location = "/";
    }

    useEffect(() => {
        logOut();
    }, []);

    return (
        <>
            {loading && (
                <div className="flex justify-center items-center h-screen">
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <TailSpin
                            height="70"
                            width="70"
                            color="#b30222"
                            ariaLabel="loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default Logout;

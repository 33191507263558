import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Grid } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./List.css";

const ListComponent = () => {
  const initialized = useRef(false);
  const gridRef = useRef(null);
  const modalRef = useRef(null);

  let latestCandleStickTimeStamp;
  let modalBackdrop;
  let data = [];
  let alreadyColumnsAdded;
  let fromTimestamp;

  function fetchData(
    tradeAlerts,
    reqParams,
    gridOptions,
    params,
    fromTimestamp
  ) {
    if (!tradeAlerts && latestCandleStickTimeStamp) {
      reqParams.set("from-timestamp", fromTimestamp);
    }
    const symbolsUrl = `https://j444xz2iu5oaxlrni7o264asgq0kbvks.lambda-url.us-east-1.on.aws/${
      !tradeAlerts ? "trade-" : ""
    }alerts?`;
    const response = fetch(symbolsUrl + reqParams.toString(), {
      headers: {
        Authorization: "LkAFZemOSK2OU8", // Add your authorization token here
      },
    })
      .then((response) => response.text())
      .then((res) => {
        res = res
          .split("\\n")
          .join("NEWLINE")
          .split("\\")
          .join("")
          .split("NEWLINE")
          .join("\\n")
          .slice(1, -1);
        res = parseCSVToJSON(res).filter((r) => r._id);

        if (!tradeAlerts) {
          if (res[0]) {
            Object.keys(res[0]).forEach((key) => {
              if (
                !gridOptions.columnDefs.find((c) => c.field === key) &&
                key !== "_id" &&
                key !== "alert_uuids" &&
                key !== "setup_id" &&
                key !== "alert_names" &&
                key !== "timestamp" &&
                key !== "name" &&
                key !== "move"
              ) {
                if (key.toLowerCase() === 'close') {
                  gridOptions.columnDefs.splice(2, 0, {
                    field: key,
                    headerName: capitalizeEachWord(key.split("_").join(" ")),
                    cellDataType: 'number',
                    valueGetter: (e) => +e.data.close
                  });
                } else {
                  gridOptions.columnDefs.push({
                    field: key,
                    headerName: capitalizeEachWord(key.split("_").join(" ")),
                  });
                }
              }
            });
            if (!alreadyColumnsAdded) {
              params.api.setGridOption("columnDefs", gridOptions.columnDefs); // Set the data using params.api
              alreadyColumnsAdded = true;
            }
          }
          latestCandleStickTimeStamp = res
            .map((d) => d.candle_timestamp)
            .reduce((max, current) => (current > max ? current : max));
        }

        data = Array.from(
          new Map([...res, ...data].map((item) => [item._id, item])).values()
        );

        params.api.setGridOption("rowData", data);

        params.api.autoSizeAllColumns();

        // if (params.api.getAllGridColumns().reduce((sum, col) => sum + col.getActualWidth(), 0) < document.querySelector('.ag-root').clientWidth) {
        //     params.api.sizeColumnsToFit();
        // }
      });
  }

  function DetailsCellRenderer(params) {
    return <button onClick={(e) => openModal(params.data.symbol, fromTimestamp)}>Details</button>;
  }

  function openModal(symbol, fromTimestamp) {
    let fromTimestampParam = "";
    if (fromTimestamp && fromTimestamp !== "null") {
      fromTimestampParam = `&from_timestamp=${fromTimestamp}`;
    }
    document.getElementById(
      "details-iframe"
    ).src = `/list?trade-alerts=true&symbol=${symbol}${fromTimestampParam}`;
    document.getElementById(
        "modal-backdrop"
      ).style.display = "block";
  }

  function openTV(symbol) {
    window.open(
      `https://www.tradingview.com/chart/?symbol=BINANCE%3A${symbol}.P`
    );
  }

  function TVCellRenderer(params) {
    return <button onClick={(e) => openTV(params.data.symbol)}>TV</button>;
  }

  function closeModal() {
    document.getElementById(
        "modal-backdrop"
      ).style.display = 'none';
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      let url = new URL(window.location.href);
      let urlParams = url.searchParams;

      const tradeAlerts = urlParams.get("trade-alerts") === "true";
      const fromTimestamp = urlParams.get("from-timestamp");

      const gridOptions = {
        columnDefs: !tradeAlerts
          ? [
              // { headerName: "Timestamp", field: "timestamp", cellRenderer: timestampCellRenderer, width: 95 },
              {
                headerName: "Candle timestamp",
                field: "candle_timestamp",
                cellRenderer: timestampCellRenderer,
                width: 95,
              },
              {
                headerName: "Symbol",
                field: "symbol",
                cellRenderer: symbolCellRenderer,
                width: 100,
              },
              { headerName: "Alert", field: "name" },
              {
                headerName: "Chart",
                field: "tv",
                cellRenderer: function (params) {
                  // Mount React component dynamically
                  const eDiv = document.createElement("div");
                  ReactDOM.render(
                    <TVCellRenderer {...params} />,
                    eDiv
                  );
                  return eDiv;
                },
                width: 50,
              },
              // { headerName: "Direction", field: "direction", width: 40 },
              // { headerName: "Setup ID", field: "setup_id", width: 40 },
              // { headerName: "Alert Names", field: "alert_names", cellRenderer: alertNamesCellRenderer },
              // { headerName: "Indicator ID", field: "_id" },
              {
                headerName: "Details",
                field: "DETAILS",
                cellRenderer: function (params) {
                    // Mount React component dynamically
                    const eDiv = document.createElement("div");
                    ReactDOM.render(
                      <DetailsCellRenderer {...params} />,
                      eDiv
                    );
                    return eDiv;
                  },
                width: 70,
              },
            ]
          : [
              {
                headerName: "Timestamp",
                field: "timestamp",
                cellRenderer: timestampCellRenderer,
              },
              {
                headerName: "Candle timestamp",
                field: "candle_timestamp",
                cellRenderer: timestampCellRenderer,
              },
              {
                headerName: "Symbol",
                field: "symbol",
                cellRenderer: symbolCellRenderer,
              },
              { headerName: "Direction", field: "direction" },
              {
                headerName: "Alert Names",
                field: "indicator_name",
                cellRenderer: symbolCellRenderer,
              },
            ],
        defaultColDef: {
          resizable: true,
          filter: true,
          sortable: true,
        },
        pagination: true,
        paginationPageSize: 50,
        animateRows: true,
        onGridReady: onGridReady, // Add the onGridReady event
      };

      function onGridReady(params) {
        let url = new URL(window.location.href);
        let urlParams = url.searchParams;
        const reqParams = new URLSearchParams({
          symbol: urlParams.get("symbol"),
          from_timestamp: urlParams.get("from_timestamp"),
        });
        if (reqParams.get("symbol") === "null") {
          reqParams.delete("symbol");
        }
        if (reqParams.get("from_timestamp") === "null") {
          reqParams.delete("from_timestamp");
        }
        fetchData(tradeAlerts, reqParams, gridOptions, params);
        if (!tradeAlerts) {
          setInterval(() => {
            fetchData(
              tradeAlerts,
              reqParams,
              gridOptions,
              params,
              latestCandleStickTimeStamp
            );
          }, 60 * 1000);
        }
      }
      const grid = new Grid(gridRef.current, gridOptions);
    }
  }, []);

  return (
    <div className="grid-main-container">
      <div id="modal-backdrop">
        <div id="modal">
          <button onClick={closeModal} id="close-btn">&times;</button>
          <iframe id="details-iframe" title="Embedded content"></iframe>
        </div>
      </div>
      <div
        ref={gridRef}
        id="myGrid"
        class="ag-theme-alpine-dark"
        style={{ height: "calc(100vh - 120px)", width: "100%" }}
      ></div>
    </div>
  );
};

function moveCellRenderer(params) {
  return params.value === "Up"
    ? '<span style="color: green">⬆</span>'
    : '<span style="color: red">⬇</span>';
}

function customCellRenderer(params) {
  const value = params.value;
  const color = value > 50 ? "lime" : "red";
  return `<span style="color: ${color}">${value}%</span>`;
}

function alertNamesCellRenderer(params) {
  let html = "";
  params.value.split(",").forEach((s) => {
    html += symbolCellRenderer({ value: s }) + " ";
  });
  return html;
}

function symbolCellRenderer(params) {
  const str = params.value;
  // Create a hash from the string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash; // Convert to 32-bit integer
  }
  // Convert the hash to an RGB color and scale to increase brightness
  let r = (hash >> 16) & 255;
  let g = (hash >> 8) & 255;
  let b = hash & 255;
  // Scale up RGB values for more vivid colors
  r = Math.min(255, Math.max(100, r + 50));
  g = Math.min(255, Math.max(100, g + 50));
  b = Math.min(255, Math.max(100, b + 50));
  return params?.colDef?.field === "symbol"
    ? `<a class="symbol-link" target="_blank" href="/chart?symbol=${str}"><span style="color: #fff; background: rgba(${r},${g},${b},0.3);padding: 5px 10px; border-radius: 50px;">${str}</span></a>`
    : `<span style="color: #fff; background: rgba(${r},${g},${b},0.3);padding: 5px 10px; border-radius: 50px;">${str}</span>`;
  // return params?.colDef?.field === 'symbol' ? `<a class="symbol-link" target="_blank" href="/chart?symbol=${str}"><span style="color: rgb(${r},${g},${b}); background: rgba(${r},${g},${b},0.15);padding: 5px 10px; border-radius: 50px;">${str}</span></a>`
  //     : `<span style="color: rgb(${r},${g},${b}); background: rgba(${r},${g},${b},0.15);padding: 5px 10px; border-radius: 50px;">${str}</span>`;
}
function timestampCellRenderer(params) {
  const value = params.value;
  return `<span>${formatTimestamp(value)}</span>`;
}

function capitalizeEachWord(str) {
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

function parseCSVToJSON(csvString) {
  // Step 1: Split the CSV string into rows, handling new lines
  const rows = csvString.trim().split("\\n");

  // Step 2: Extract the headers
  const headers = rows[0].split(",");

  // Step 3: Map the rest of the rows into JSON objects
  const jsonData = rows.slice(1).map((row) => {
    // Parse each row into an array of values, handling nested arrays properly
    const values = [];
    let current = "";
    let inQuotes = false;

    for (let i = 0; i < row.length; i++) {
      if (row[i] === '"' && (i === 0 || row[i - 1] !== "\\")) {
        inQuotes = !inQuotes;
      } else if (row[i] === "," && !inQuotes) {
        values.push(current.trim());
        current = "";
      } else {
        current += row[i];
      }
    }
    values.push(current.trim()); // Push the last value

    // Create an object mapping headers to values
    return headers.reduce((obj, header, index) => {
      let value = values[index];

      // If value is a JSON-like array string, attempt to parse it
      if (value && value.startsWith("['") && value.endsWith("']")) {
        try {
          value = JSON.parse(value.replace(/'/g, '"')); // Replace single quotes and parse
        } catch (e) {
          console.warn(`Failed to parse array for header "${header}":`, value);
        }
      }

      obj[header] = value;
      return obj;
    }, {});
  });

  return jsonData;
}

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  let formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  let splitDate = formattedDate.split(",");
  let year = "'" + splitDate[1].slice(-2);

  splitDate[1] = year;

  return splitDate.join("  ");
}

export default ListComponent;

import { useState, useEffect } from "react";
import {
  Button,
  Input,
  Typography,
  Switch,
  Select,
  Option,
} from "@material-tailwind/react";
import { TailSpin } from "react-loader-spinner";
import {
  getExchangeConfig,
  updateExchangeConfig,
} from "../services/exchangeService";
import TradesComponent from "./Trades/Trades";
import {
  getAllSimulationConfig,
  getSimulationConfig,
  postSimulationConfig,
  getSimulationTradesHistory,
} from "../services/simulationService";

function Configuration({ simulate }) {
  const [selectDisabled, setSelectDisabled] = useState(true);
  const [disableSave, setDisableSave] = useState(false);
  const [configsInterval, setConfigsInterval] = useState(null);
  const [simulationConfig, setSimulationConfig] = useState([]);
  const [tradesHistory, setTradesHistory] = useState([]);
  const [doneConfig, setDoneConfig] = useState({});
  const [exchange, setExchange] = useState({
    uuid: "",
    enabled: false,
    max_open_trades: 1,
    max_trade_duration_minutes: 0,
    from_timestamp: null,
    to_timestamp: null,
    api_key: "",
    api_secret: "",
    status: "",
    inserted_date: "",
    sl_percent: 0.0,
    tp_percent: 0.0,
    trade_amount: 0.0,
    leverage: 0.0,
    allowed_alert_setup_ids_entry_long: [],
    allowed_alert_setup_ids_entry_short: [],
    allowed_alert_setup_ids_exit_long: [],
    allowed_alert_setup_ids_exit_short: [],
    allow_tight_range_alert_filter_entry_long: false,
    allow_tight_range_alert_filter_entry_short: false,
    allow_tight_range_alert_filter_exit_long: false,
    allow_tight_range_alert_filter_exit_short: false,
    allow_candle_color_filter_count_entry_long: false,
    allow_candle_color_filter_count_entry_short: false,
    allow_candle_color_filter_count_exit_long: false,
    allow_candle_color_filter_count_exit_short: false,
    allow_rvol_filter_entry_long: false,
    allow_rvol_filter_entry_short: false,
    allow_rvol_filter_exit_long: false,
    allow_rvol_filter_exit_short: false,
    candle_color_filter_count_entry_long: 0,
    candle_color_filter_count_entry_short: 0,
    candle_color_filter_count_exit_long: 0,
    candle_color_filter_count_exit_short: 0,
  });

  const [remoteExchange, setRemoteExchange] = useState({
    uuid: "",
    enabled: false,
    max_open_trades: 1,
    max_trade_duration_minutes: 0,
    from_timestamp: null,
    to_timestamp: null,
    api_key: "",
    api_secret: "",
    status: "",
    inserted_date: "",
    sl_percent: 0.0,
    tp_percent: 0.0,
    trade_amount: 0.0,
    leverage: 0.0,
    allowed_alert_setup_ids_entry_long: [],
    allowed_alert_setup_ids_entry_short: [],
    allowed_alert_setup_ids_exit_long: [],
    allowed_alert_setup_ids_exit_short: [],
    allow_tight_range_alert_filter_entry_long: false,
    allow_tight_range_alert_filter_entry_short: false,
    allow_tight_range_alert_filter_exit_long: false,
    allow_tight_range_alert_filter_exit_short: false,
    allow_candle_color_filter_count_entry_long: false,
    allow_candle_color_filter_count_entry_short: false,
    allow_candle_color_filter_count_exit_long: false,
    allow_candle_color_filter_count_exit_short: false,
    allow_rvol_filter_entry_long: false,
    allow_rvol_filter_entry_short: false,
    allow_rvol_filter_exit_long: false,
    allow_rvol_filter_exit_short: false,
    candle_color_filter_count_entry_long: 0,
    candle_color_filter_count_entry_short: 0,
    candle_color_filter_count_exit_long: 0,
    candle_color_filter_count_exit_short: 0,
  });

  const [clonedExchange, setClonedExchange] = useState({});

  const [loading, setLoading] = useState(true); // State to control the loading spinner

  useEffect(() => {
    const populateExchange = async () => {
      setLoading(true); // Start the loader
      try {
        const { data: exchangeConfig } = simulate
          ? await getSimulationConfig()
          : await getExchangeConfig();
        if (simulate) {
          const { data: allConfig } = await getAllSimulationConfig();
          setSimulationConfig(allConfig);
        }

        extendExchanges(exchangeConfig);

        if (
          exchangeConfig.status === "pending" ||
          exchangeConfig.status === "working"
        ) {
          setDisableSave(true);
        } else {
          setDoneConfig(exchangeConfig);
          getLatestConfigTradesHistory(exchangeConfig.uuid);
        }
      } catch (error) {
        console.error("Failed to fetch exchange config:", error);
      } finally {
        setLoading(false); // Stop the loader
      }
    };

    populateExchange();

    if (simulate) {
      setConfigsInterval(
        setInterval(() => {
          getLatestConfig();
        }, 30000)
      );
    }

    return () => {
      if (simulate) {
        if (configsInterval) {
          clearInterval(configsInterval);
          setConfigsInterval(null);
        }
      }
    };
  }, []);

  const getLatestConfig = async () => {
    const { data: latestSimulation } = await getSimulationConfig();
    if (
      latestSimulation.status === "pending" ||
      latestSimulation.status === "working"
    ) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
      setDoneConfig(latestSimulation);
      getLatestConfigTradesHistory(latestSimulation.uuid);
    }
  };

  const getLatestConfigTradesHistory = async (uuid) => {
    const { data: tradesHistoryResponse } = await getSimulationTradesHistory(uuid);
    setTradesHistory(tradesHistoryResponse);
  };

  const handleLoadSimulationToggle = (e) => {
    const { checked } = e.target;
    setSelectDisabled(checked);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    let parsedValue = value;

    if (type === "number") {
      parsedValue = value === "" ? "" : parseFloat(value);
    } else if (type === "checkbox") {
      parsedValue = checked;
    }

    setExchange((prevState) => ({
      ...prevState,
      [name]: parsedValue,
    }));
  };

  const handleIDChange = (e, arrName, id) => {
    const { checked, name } = e.target;
    if (checked) {
      setExchange((prevState) => ({
        ...prevState,
        [arrName]: [id, ...prevState[arrName]],
      }));
    } else {
      setExchange((prevState) => ({
        ...prevState,
        [arrName]: [...prevState[arrName].filter((cID) => cID !== id)],
      }));
    }
    setClonedExchange((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const extendExchanges = (exchangeConfig) => {
    setExchange({
      enabled: exchangeConfig.enabled,
      max_open_trades: exchangeConfig.max_open_trades,
      from_timestamp: exchangeConfig.from_timestamp ? new Date(exchangeConfig.from_timestamp).toLocaleString()?.split(',')[0].replace(/\//g,'-').split('-').reverse().join('-') : undefined,
      to_timestamp: exchangeConfig.to_timestamp ? new Date(exchangeConfig.to_timestamp).toLocaleString()?.split(',')[0].replace(/\//g,'-').split('-').reverse().join('-') : undefined,
      max_trade_duration_minutes: exchangeConfig.max_trade_duration_minutes,
      api_key: exchangeConfig.api_key,
      api_secret: exchangeConfig.api_secret,
      status: exchangeConfig.status,
      inserted_date: exchangeConfig.inserted_date,
      uuid: exchangeConfig.uuid,
      sl_percent: exchangeConfig.sl_percent,
      tp_percent: exchangeConfig.tp_percent,
      trade_amount: exchangeConfig.trade_amount,
      leverage: exchangeConfig.leverage,
      allowed_alert_setup_ids_entry_long:
        exchangeConfig.allowed_alert_setup_ids_entry_long || [],
      allowed_alert_setup_ids_entry_short:
        exchangeConfig.allowed_alert_setup_ids_entry_short || [],
      allowed_alert_setup_ids_exit_long:
        exchangeConfig.allowed_alert_setup_ids_exit_long || [],
      allowed_alert_setup_ids_exit_short:
        exchangeConfig.allowed_alert_setup_ids_exit_short || [],
      allow_candle_color_filter_count_entry_long:
        exchangeConfig.allow_candle_color_filter_count_entry_long,
      allow_candle_color_filter_count_entry_short:
        exchangeConfig.allow_candle_color_filter_count_entry_short,
      allow_candle_color_filter_count_exit_long:
        exchangeConfig.allow_candle_color_filter_count_exit_long,
      allow_candle_color_filter_count_exit_short:
        exchangeConfig.allow_candle_color_filter_count_exit_short,
      allow_tight_range_alert_filter_entry_long:
        exchangeConfig.allow_tight_range_alert_filter_entry_long,
      allow_tight_range_alert_filter_entry_short:
        exchangeConfig.allow_tight_range_alert_filter_entry_short,
      allow_tight_range_alert_filter_exit_long:
        exchangeConfig.allow_tight_range_alert_filter_exit_long,
      allow_tight_range_alert_filter_exit_short:
        exchangeConfig.allow_tight_range_alert_filter_exit_short,
      allow_rvol_filter_entry_long: exchangeConfig.allow_rvol_filter_entry_long,
      allow_rvol_filter_entry_short:
        exchangeConfig.allow_rvol_filter_entry_short,
      allow_rvol_filter_exit_long: exchangeConfig.allow_rvol_filter_exit_long,
      allow_rvol_filter_exit_short: exchangeConfig.allow_rvol_filter_exit_short,
      candle_color_filter_count_entry_long:
        exchangeConfig.candle_color_filter_count_entry_long,
      candle_color_filter_count_entry_short:
        exchangeConfig.candle_color_filter_count_entry_short,
      candle_color_filter_count_exit_long:
        exchangeConfig.candle_color_filter_count_exit_long,
      candle_color_filter_count_exit_short:
        exchangeConfig.candle_color_filter_count_exit_short,
    });

    setRemoteExchange({
      enabled: exchangeConfig.enabled,
      max_open_trades: exchangeConfig.max_open_trades,
      max_trade_duration_minutes: exchangeConfig.max_trade_duration_minutes,
      from_timestamp: exchangeConfig.from_timestamp ? new Date(exchangeConfig.from_timestamp).toLocaleString()?.split(',')[0].replace(/\//g,'-').split('-').reverse().join('-') : undefined,
      to_timestamp: exchangeConfig.to_timestamp ? new Date(exchangeConfig.to_timestamp).toLocaleString()?.split(',')[0].replace(/\//g,'-').split('-').reverse().join('-') : undefined,
      api_key: exchangeConfig.api_key,
      api_secret: exchangeConfig.api_secret,
      status: exchangeConfig.status,
      inserted_date: exchangeConfig.inserted_date,
      uuid: exchangeConfig.uuid,
      sl_percent: exchangeConfig.sl_percent,
      tp_percent: exchangeConfig.tp_percent,
      trade_amount: exchangeConfig.trade_amount,
      leverage: exchangeConfig.leverage,
      allowed_alert_setup_ids_entry_long:
        exchangeConfig.allowed_alert_setup_ids_entry_long || [],
      allowed_alert_setup_ids_entry_short:
        exchangeConfig.allowed_alert_setup_ids_entry_short || [],
      allowed_alert_setup_ids_exit_long:
        exchangeConfig.allowed_alert_setup_ids_exit_long || [],
      allowed_alert_setup_ids_exit_short:
        exchangeConfig.allowed_alert_setup_ids_exit_short || [],
      allow_candle_color_filter_count_entry_long:
        exchangeConfig.allow_candle_color_filter_count_entry_long,
      allow_candle_color_filter_count_entry_short:
        exchangeConfig.allow_candle_color_filter_count_entry_short,
      allow_candle_color_filter_count_exit_long:
        exchangeConfig.allow_candle_color_filter_count_exit_long,
      allow_candle_color_filter_count_exit_short:
        exchangeConfig.allow_candle_color_filter_count_exit_short,
      allow_tight_range_alert_filter_entry_long:
        exchangeConfig.allow_tight_range_alert_filter_entry_long,
      allow_tight_range_alert_filter_entry_short:
        exchangeConfig.allow_tight_range_alert_filter_entry_short,
      allow_tight_range_alert_filter_exit_long:
        exchangeConfig.allow_tight_range_alert_filter_exit_long,
      allow_tight_range_alert_filter_exit_short:
        exchangeConfig.allow_tight_range_alert_filter_exit_short,
      allow_rvol_filter_entry_long: exchangeConfig.allow_rvol_filter_entry_long,
      allow_rvol_filter_entry_short:
        exchangeConfig.allow_rvol_filter_entry_short,
      allow_rvol_filter_exit_long: exchangeConfig.allow_rvol_filter_exit_long,
      allow_rvol_filter_exit_short: exchangeConfig.allow_rvol_filter_exit_short,
      candle_color_filter_count_entry_long:
        exchangeConfig.candle_color_filter_count_entry_long,
      candle_color_filter_count_entry_short:
        exchangeConfig.candle_color_filter_count_entry_short,
      candle_color_filter_count_exit_long:
        exchangeConfig.candle_color_filter_count_exit_long,
      candle_color_filter_count_exit_short:
        exchangeConfig.candle_color_filter_count_exit_short,
    });

    setClonedExchange({
      entry_conditions_longs_opening_long:
        exchangeConfig["allowed_alert_setup_ids_entry_long"].indexOf(0) > -1,
      entry_conditions_longs_closing_long:
        exchangeConfig["allowed_alert_setup_ids_entry_long"].indexOf(1) > -1,
      entry_conditions_shorts_opening_long:
        exchangeConfig["allowed_alert_setup_ids_entry_long"].indexOf(2) > -1,
      entry_conditions_shorts_closing_long:
        exchangeConfig["allowed_alert_setup_ids_entry_long"].indexOf(3) > -1,

      entry_conditions_longs_opening_short:
        exchangeConfig["allowed_alert_setup_ids_entry_short"].indexOf(0) > -1,
      entry_conditions_longs_closing_short:
        exchangeConfig["allowed_alert_setup_ids_entry_short"].indexOf(1) > -1,
      entry_conditions_shorts_opening_short:
        exchangeConfig["allowed_alert_setup_ids_entry_short"].indexOf(2) > -1,
      entry_conditions_shorts_closing_short:
        exchangeConfig["allowed_alert_setup_ids_entry_short"].indexOf(3) > -1,

      exit_conditions_longs_opening_long:
        exchangeConfig["allowed_alert_setup_ids_exit_long"].indexOf(0) > -1,
      exit_conditions_longs_closing_long:
        exchangeConfig["allowed_alert_setup_ids_exit_long"].indexOf(1) > -1,
      exit_conditions_shorts_opening_long:
        exchangeConfig["allowed_alert_setup_ids_exit_long"].indexOf(2) > -1,
      exit_conditions_shorts_closing_long:
        exchangeConfig["allowed_alert_setup_ids_exit_long"].indexOf(3) > -1,

      exit_conditions_longs_opening_short:
        exchangeConfig["allowed_alert_setup_ids_exit_short"].indexOf(0) > -1,
      exit_conditions_longs_closing_short:
        exchangeConfig["allowed_alert_setup_ids_exit_short"].indexOf(1) > -1,
      exit_conditions_shorts_opening_short:
        exchangeConfig["allowed_alert_setup_ids_exit_short"].indexOf(2) > -1,
      exit_conditions_shorts_closing_short:
        exchangeConfig["allowed_alert_setup_ids_exit_short"].indexOf(3) > -1,
    });
  };

  const handleReset = () => {
    if (simulate) {
      setDisableSave(false);
    } else {
      setExchange(remoteExchange);
    }
  };

  const handleConfigChange = async (uuid) => {
    setTradesHistory([]);
    const { data: tradesHistoryResponse } = await getSimulationTradesHistory(
      uuid
    );
    setTradesHistory(tradesHistoryResponse);
    const { data: loadedConfig } = await getSimulationConfig(uuid);
    extendExchanges(loadedConfig);
  };

  const handleSave = async () => {
    setLoading(true); // Start the loader during save
    try {
      // const exchangeClone = {...exchange};
      // delete exchangeClone.api_key;
      // delete exchangeClone.api_secret;
      if (simulate) {
        setDisableSave(true);
        setDoneConfig(null);
      }
      const postedExchange = {...exchange};
      delete postedExchange.uuid;
      delete postedExchange.inserted_date;
      if (postedExchange.from_timestamp) {
        postedExchange.from_timestamp = new Date(new Date(postedExchange.from_timestamp)).toISOString();
      } else {
        delete postedExchange.from_timestamp; 
      }
      if (postedExchange.to_timestamp) {
        postedExchange.to_timestamp = new Date(new Date(postedExchange.to_timestamp)).toISOString();
      } else {
        delete postedExchange.to_timestamp; 
      }
      const { data: updatedExchange } = simulate
        ? await postSimulationConfig(postedExchange)
        : updateExchangeConfig(postedExchange);
      setRemoteExchange(updatedExchange);
    } catch (error) {
      console.error("Failed to save exchange config:", error);
    } finally {
      setLoading(false); // Stop the loader
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <TailSpin
              height="70"
              width="70"
              color="#b30222"
              ariaLabel="loading"
              visible={true}
            />
          </div>
        </div>
      ) : (
        <form className="mt-5 mx-auto py-20 px-6 container">
          <Typography variant="h3" color="white">
            {simulate
              ? "Trade Simulation"
              : "Configure Crypto Exchange Settings"}
          </Typography>
          {!simulate && (
            <Typography
              variant="small"
              className="text-gray-400 font-normal mt-1"
            >
              Make sure to fill in all the fields accordingly
            </Typography>
          )}
          <br />
          <div className="flex flex-col mt-8">
            {!simulate && (
              <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
                <div className="w-full md:mb-5">
                  <Typography variant="h6" color="white" className="mb-2">
                    Enable Trading
                  </Typography>
                  <Switch
                    name="enabled"
                    color="red"
                    checked={exchange.enabled}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
            {simulate && (
              <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
                <div className="w-full md:mb-5">
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="py-3"
                  >
                    <Switch
                      name="load_simulation"
                      color="red"
                      checked={selectDisabled}
                      onChange={handleLoadSimulationToggle}
                    />
                    <label style={{ color: "#fff", marginLeft: "10px" }}>
                      Load Simulation
                    </label>
                  </div>
                  <Select
                    disabled={!selectDisabled}
                    onChange={handleConfigChange}
                    label="Select Version"
                    className="w-full text-white"
                    color="red"
                  >
                    {simulationConfig.map((c) => {
                      return (
                        <Option key={c.uuid} value={c.uuid}>
                          {c.inserted_date} - {c.uuid}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            )}
            {simulate && (
              <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
                <div className="w-full">
                  <Typography variant="h6" color="white" className="mb-2">
                    From Timestamp
                  </Typography>
                  <Input
                    type="date"
                    name="from_timestamp"
                    value={exchange.from_timestamp}
                    onChange={handleChange}
                    size="lg"
                    placeholder="From Timestamp"
                    labelProps={{
                      className: "hidden",
                    }}
                    className="datetime-input w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                  />
                </div>
                <div className="w-full">
                  <Typography variant="h6" color="white" className="mb-2">
                    To Timestamp
                  </Typography>
                  <Input
                    type="date"
                    name="to_timestamp"
                    value={exchange.to_timestamp}
                    onChange={handleChange}
                    size="lg"
                    placeholder="To Timestamp"
                    labelProps={{
                      className: "hidden",
                    }}
                    className="datetime-input w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                  />
                </div>
              </div>
            )}
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              <div className="w-full">
                <Typography variant="h6" color="white" className="mb-2">
                  Max Trade Duration (minutes)
                </Typography>
                <Input
                  type="number"
                  step="1"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  name="max_trade_duration_minutes"
                  value={exchange.max_trade_duration_minutes}
                  onChange={handleChange}
                  size="lg"
                  placeholder="Max Trade Duration (minutes)"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                />
              </div>
              <div className="w-full">
                <Typography variant="h6" color="white" className="mb-2">
                  Max Open Trades
                </Typography>
                <Input
                  type="number"
                  step="1"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  name="max_open_trades"
                  value={exchange.max_open_trades}
                  onChange={handleChange}
                  size="lg"
                  placeholder="Max Open Trades"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                />
              </div>
            </div>
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              {/* <div className="w-full">
                <Typography
                  variant="h6"
                  color="white"
                  className="mb-2"
                >
                  API Key
                </Typography>
                <Input
                  type="text"
                  name="api_key"
                  value={exchange.api_key}
                  onChange={handleChange}
                  size="lg"
                  placeholder="API Key"
                  className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>
              <div className="w-full">
                <Typography
                  variant="h6"
                  color="white"
                  className="mb-2"
                >
                  API Secret
                </Typography>
                <Input
                  type="password"
                  name="api_secret"
                  value={exchange.api_secret}
                  onChange={handleChange}
                  size="lg"
                  placeholder="API Secret"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                />
              </div> */}
            </div>
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              <div className="w-full">
                <Typography variant="h6" color="white" className="mb-2">
                  Stop Loss
                </Typography>
                <Input
                  type="number"
                  step="0.01"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '');"
                  name="sl_percent"
                  value={exchange.sl_percent}
                  onChange={handleChange}
                  size="lg"
                  placeholder="0.0%"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                />
              </div>
              <div className="w-full">
                <Typography variant="h6" color="white" className="mb-2">
                  Take Profit
                </Typography>
                <Input
                  type="number"
                  step="0.01"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '');"
                  name="tp_percent"
                  value={exchange.tp_percent}
                  onChange={handleChange}
                  size="lg"
                  placeholder="0.0%"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                />
              </div>
            </div>
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              <div className="w-full">
                <Typography variant="h6" color="white" className="mb-2">
                  Margin (Trade Amount)
                </Typography>
                <Input
                  type="number"
                  step="0.01"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '');"
                  name="trade_amount"
                  value={exchange.trade_amount}
                  onChange={handleChange}
                  size="lg"
                  placeholder="0.0"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                />
              </div>

              <div className="w-full">
                <Typography variant="h6" color="white" className="mb-2">
                  Leverage
                </Typography>
                <Input
                  type="number"
                  step="0.01"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '');"
                  name="leverage"
                  value={exchange.leverage}
                  onChange={handleChange}
                  min="0"
                  max="100"
                  size="lg"
                  placeholder="0.0"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                />
              </div>
            </div>
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              <div className="w-full">
                <Typography variant="h5" color="white" className="mb-2">
                  Entry Conditions
                </Typography>
                <Typography variant="h6" color="white" className="mb-2">
                  Long
                </Typography>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="entry_conditions_longs_opening_long"
                    color="red"
                    checked={clonedExchange.entry_conditions_longs_opening_long}
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_entry_long", 0)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Longs Opening
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="entry_conditions_longs_closing_long"
                    color="red"
                    checked={clonedExchange.entry_conditions_longs_closing_long}
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_entry_long", 1)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Longs Closing
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="entry_conditions_shorts_opening_long"
                    color="red"
                    checked={
                      clonedExchange.entry_conditions_shorts_opening_long
                    }
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_entry_long", 2)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Shorts Opening
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="entry_conditions_shorts_closing_long"
                    color="red"
                    checked={
                      clonedExchange.entry_conditions_shorts_closing_long
                    }
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_entry_long", 3)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Shorts Closing
                  </label>
                </div>
              </div>
              <div className="w-full">
                <Typography variant="h6" color="white" className="mb-2">
                  Short
                </Typography>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="entry_conditions_longs_opening_short"
                    color="red"
                    checked={
                      clonedExchange.entry_conditions_longs_opening_short
                    }
                    onChange={(e) =>
                      handleIDChange(
                        e,
                        "allowed_alert_setup_ids_entry_short",
                        0
                      )
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Longs Opening
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="entry_conditions_longs_closing_short"
                    color="red"
                    checked={
                      clonedExchange.entry_conditions_longs_closing_short
                    }
                    onChange={(e) =>
                      handleIDChange(
                        e,
                        "allowed_alert_setup_ids_entry_short",
                        1
                      )
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Longs Closing
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="entry_conditions_shorts_opening_short"
                    color="red"
                    checked={
                      clonedExchange.entry_conditions_shorts_opening_short
                    }
                    onChange={(e) =>
                      handleIDChange(
                        e,
                        "allowed_alert_setup_ids_entry_short",
                        2
                      )
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Shorts Opening
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="entry_conditions_shorts_closing_short"
                    color="red"
                    checked={
                      clonedExchange.entry_conditions_shorts_closing_short
                    }
                    onChange={(e) =>
                      handleIDChange(
                        e,
                        "allowed_alert_setup_ids_entry_short",
                        3
                      )
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Shorts Closing
                  </label>
                </div>
              </div>
              <div className="w-full">
                <Typography variant="h5" color="white" className="mb-2">
                  Exit Conditions
                </Typography>
                <Typography variant="h6" color="white" className="mb-2">
                  Long
                </Typography>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="exit_conditions_longs_opening_long"
                    color="red"
                    checked={clonedExchange.exit_conditions_longs_opening_long}
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_exit_long", 0)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Longs Opening
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="exit_conditions_longs_closing_long"
                    color="red"
                    checked={clonedExchange.exit_conditions_longs_closing_long}
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_exit_long", 1)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Longs Closing
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="exit_conditions_shorts_opening_long"
                    color="red"
                    checked={clonedExchange.exit_conditions_shorts_opening_long}
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_exit_long", 2)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Shorts Opening
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="exit_conditions_shorts_closing_long"
                    color="red"
                    checked={clonedExchange.exit_conditions_shorts_closing_long}
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_exit_long", 3)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Shorts Closing
                  </label>
                </div>
              </div>
              <div className="w-full">
                <Typography variant="h6" color="white" className="mb-2">
                  Short
                </Typography>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="exit_conditions_longs_opening_short"
                    color="red"
                    checked={clonedExchange.exit_conditions_longs_opening_short}
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_exit_short", 0)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Longs Opening
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="exit_conditions_longs_closing_short"
                    color="red"
                    checked={clonedExchange.exit_conditions_longs_closing_short}
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_exit_short", 1)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Longs Closing
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="exit_conditions_shorts_opening_short"
                    color="red"
                    checked={
                      clonedExchange.exit_conditions_shorts_opening_short
                    }
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_exit_short", 2)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Shorts Opening
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="exit_conditions_shorts_closing_short"
                    color="red"
                    checked={
                      clonedExchange.exit_conditions_shorts_closing_short
                    }
                    onChange={(e) =>
                      handleIDChange(e, "allowed_alert_setup_ids_exit_short", 3)
                    }
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Shorts Closing
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              <div className="w-full">
                <Typography variant="h5" color="white" className="mb-2">
                  Entry Filters
                </Typography>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_tight_range_alert_filter_entry_long"
                    color="red"
                    checked={exchange.allow_tight_range_alert_filter_entry_long}
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    {simulate ? 'TC' : 'Range'}
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_candle_color_filter_count_entry_long"
                    color="red"
                    checked={
                      exchange.allow_candle_color_filter_count_entry_long
                    }
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Candle Color
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_rvol_filter_entry_long"
                    color="red"
                    checked={exchange.allow_rvol_filter_entry_long}
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    RVOL
                  </label>
                </div>
                <div className="w-full">
                  <Typography variant="h6" color="white" className="mb-2">
                    Candle Count
                  </Typography>
                  <Input
                    type="number"
                    step="1"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '');"
                    name="candle_color_filter_count_entry_long"
                    value={exchange.candle_color_filter_count_entry_long}
                    onChange={handleChange}
                    size="lg"
                    placeholder="0"
                    labelProps={{
                      className: "hidden",
                    }}
                    className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                  />
                </div>
              </div>
              <div className="w-full">
                <Typography variant="h5" color="white" className="mb-2">
                  Entry Filters
                </Typography>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_tight_range_alert_filter_entry_short"
                    color="red"
                    checked={
                      exchange.allow_tight_range_alert_filter_entry_short
                    }
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    {simulate ? 'TC' : 'Range'}
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_candle_color_filter_count_entry_short"
                    color="red"
                    checked={
                      exchange.allow_candle_color_filter_count_entry_short
                    }
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Candle Color
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_rvol_filter_entry_short"
                    color="red"
                    checked={exchange.allow_rvol_filter_entry_short}
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    RVOL
                  </label>
                </div>
                <div className="w-full">
                  <Typography variant="h6" color="white" className="mb-2">
                    Candle Count
                  </Typography>
                  <Input
                    type="number"
                    step="1"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '');"
                    name="candle_color_filter_count_entry_short"
                    value={exchange.candle_color_filter_count_entry_short}
                    onChange={handleChange}
                    size="lg"
                    placeholder="0"
                    labelProps={{
                      className: "hidden",
                    }}
                    className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                  />
                </div>
              </div>
              <div className="w-full">
                <Typography variant="h5" color="white" className="mb-2">
                  Exit Filters
                </Typography>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_tight_range_alert_filter_exit_long"
                    color="red"
                    checked={exchange.allow_tight_range_alert_filter_exit_long}
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    {simulate ? 'TC' : 'Range'}
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_candle_color_filter_count_exit_long"
                    color="red"
                    checked={exchange.allow_candle_color_filter_count_exit_long}
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Candle Color
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_rvol_filter_exit_long"
                    color="red"
                    checked={exchange.allow_rvol_filter_exit_long}
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    RVOL
                  </label>
                </div>
                <div className="w-full">
                  <Typography variant="h6" color="white" className="mb-2">
                    Candle Count
                  </Typography>
                  <Input
                    type="number"
                    step="1"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '');"
                    name="candle_color_filter_count_exit_long"
                    value={exchange.candle_color_filter_count_exit_long}
                    onChange={handleChange}
                    size="lg"
                    placeholder="0"
                    labelProps={{
                      className: "hidden",
                    }}
                    className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                  />
                </div>
              </div>
              <div className="w-full">
                <Typography variant="h5" color="white" className="mb-2">
                  Exit Filters
                </Typography>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_tight_range_alert_filter_exit_short"
                    color="red"
                    checked={exchange.allow_tight_range_alert_filter_exit_short}
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    {simulate ? 'TC' : 'Range'}
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_candle_color_filter_count_exit_short"
                    color="red"
                    checked={
                      exchange.allow_candle_color_filter_count_exit_short
                    }
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    Candle Color
                  </label>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="py-1"
                >
                  <Switch
                    name="allow_rvol_filter_exit_short"
                    color="red"
                    checked={exchange.allow_rvol_filter_exit_short}
                    onChange={handleChange}
                  />
                  <label style={{ color: "#fff", marginLeft: "10px" }}>
                    RVOL
                  </label>
                </div>
                <div className="w-full">
                  <Typography variant="h6" color="white" className="mb-2">
                    Candle Count
                  </Typography>
                  <Input
                    type="number"
                    step="1"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '');"
                    name="candle_color_filter_count_exit_short"
                    value={exchange.candle_color_filter_count_exit_short}
                    onChange={handleChange}
                    size="lg"
                    placeholder="0"
                    labelProps={{
                      className: "hidden",
                    }}
                    className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end gap-4 md:grid md:grid-cols-4">
              <div className="w-full"></div>
              <div className="w-full"></div>
              <div className="w-full">
                <Button
                  className="md:mt-12 bg-gray-800"
                  fullWidth
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </div>
              <div className="w-full">
                <Button
                  className="md:mt-15"
                  style={{ backgroundColor: "#b30222" }}
                  fullWidth
                  disabled={disableSave === true}
                  onClick={handleSave}
                >
                  <div className="flex justify-center items-center mx-auto gap-1">
                    {simulate ? "Simulate" : "Save"}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
      {simulate && doneConfig && (
        <>
          <div style={{ padding: "0 100px" }}>
            <div className="text-white gap-6 flex">
              <div>
                <strong>PNL: </strong> <span>{doneConfig.pnl_percent}%</span>
              </div>
              <div>
                <strong>#Profit: </strong> <span>{doneConfig.n_profit}</span>
              </div>
              <div>
                <strong>Avg. Profit: </strong>{" "}
                <span>{doneConfig.avg_profit}%</span>
              </div>
              <div>
                <strong>#Trades </strong> <span>{doneConfig.n_trades}</span>
              </div>
              <div>
                <strong>#Loss </strong> <span>{doneConfig.n_loss}</span>
              </div>
              <div>
                <strong>Avg. Loss: </strong> <span>{doneConfig.avg_loss}%</span>
              </div>
            </div>
            {tradesHistory?.length && (
              <TradesComponent trades={tradesHistory} />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Configuration;

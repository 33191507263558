import React, { useEffect, useState } from 'react';
import {
  Typography,
  Input,
} from "@material-tailwind/react";
import { TailSpin } from 'react-loader-spinner';
import { fetchUserAttributes } from "aws-amplify/auth"

const UserProfile = () => {
  const [userAttributes, setUserAttributes] = useState({});
  const [loading, setLoading] = useState(true); // State to control the loading spinner

  async function populateUserAttributes() {
    setLoading(true); // Start the loader
    try {
      const userAttributes = await fetchUserAttributes();
      setUserAttributes(userAttributes);
    } catch (error) {
      console.error("Failed to fetch user attributes:", error);
    } finally {
      setLoading(false); // Stop the loader
    }
  }

  useEffect(() => {
    populateUserAttributes();
  }, []);

return (
  <>
    {loading ? (
      <div className="flex justify-center items-center h-screen">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <TailSpin
            height="70"
            width="70"
            color="#b30222"
            ariaLabel="loading"
            visible={true}
          />
        </div>
      </div>
    ) : (
      <div className="mt-14 mb-2 max-w-screen-lg mx-auto px-6">
        <div className="flex flex-col gap-6 max-w-lg mx-auto">
          <div className="mt-10 flex flex-col gap-1 mb-8">
            <Typography variant="h3" color="white">
              My Profile
            </Typography>
          </div>
          {Object.entries(userAttributes).map(([key, value]) => (
            <div key={key} className="flex flex-col gap-1">
              <Typography
                variant="h6"
                color="white"
                style={{ textTransform: "capitalize" }}
              >
                {key.replace(/_/g, ' ')}
              </Typography>
              <Input
                type="text"
                size="lg"
                value={value}
                readOnly
                className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500 bg-gray-800"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
            </div>
          ))}
        </div>
      </div>
    )}
  </>
);
};

export default UserProfile;

import React, { useEffect, useRef, useState } from "react";
import { createChart, setMarkers } from "lightweight-charts";
import "./Chart.css";

const ChartComponent = () => {
  const chartContainerRef = useRef(null);

  const [symbols, setSymbols] = useState([]);
  const [cache, setCache] = useState([]);
  const [candlestickSeries, setCandlestickSeries] = useState(null);

  useEffect(() => {
    const symbolsUrl =
      "https://j444xz2iu5oaxlrni7o264asgq0kbvks.lambda-url.us-east-1.on.aws/symbols";
    const response = fetch(symbolsUrl, {
      headers: {
        Authorization: "LkAFZemOSK2OU8", // Add your authorization token here
      },
    });
    response
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // Ensure response is a valid Response object
      })
      .then((d) => {
        const symbolSelector = document.getElementById("SymbolSelector");
        const symbols = [];
        d.replace('"', "")
          .replace('"', "")
          .split(",")
          .forEach((element) => {
            symbolSelector.add(new Option(element, element));
            symbols.push(element);
          });
        let url = new URL(window.location.href);
        let urlParams = url.searchParams;

        setSymbols(symbols);

        const symbol = urlParams.get("symbol") || 'BTCUSDT';
        symbolSelector.value = symbol;
      });

  }, []);

  function openTVChart() {
    let url = new URL(window.location.href);
    let params = url.searchParams;

    window.open(
      `https://www.tradingview.com/chart/?symbol=BINANCE%3A${params.get(
        "symbol"
      )}.P`
    );
  }

  function fetchData() {
    let url = new URL(window.location.href);
    let urlParams = url.searchParams;

    let data = [];

    const symbol = urlParams.get("symbol") || 'BTCUSDT';
    let params = url.searchParams;
    params.set("symbol", symbol);
    window.history.replaceState({}, "", url);

    document.getElementById("SymbolSelector").value = symbol;

    let csvUrl;
    if (urlParams.get("from_timestamp")) {
      csvUrl = `https://j444xz2iu5oaxlrni7o264asgq0kbvks.lambda-url.us-east-1.on.aws/?symbol=${symbol}&from_timestamp=${urlParams.get(
        "from_timestamp"
      )}`;
    } else {
      csvUrl = `https://j444xz2iu5oaxlrni7o264asgq0kbvks.lambda-url.us-east-1.on.aws/?symbol=${symbol}`;
    }

    try {
      const response = fetch(csvUrl, {
        headers: {
          Authorization: "LkAFZemOSK2OU8", // Add your authorization token here
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text(); // Ensure response is a valid Response object
        })
        .then((csvText) => {
          const jsonData = parseCsvToJson(
            csvText.replace('"', "").replace('"', "")
          );
          data = jsonData
            .filter((d) => d.timestamp)
            .map((d) => {
              // d.time = convertToUTCTimestamp(d.timestamp) / 1000;
              d.timestamp = convertToTimezone(d.timestamp);
              // d.timestamp = new Date(new Date(d.timestamp).setHours(new Date(d.timestamp).getHours())).toISOString();
              d.time = new Date(d.timestamp).getTime() / 1000;
              // d.time = new Date(new Date(d.timestamp).setMinutes(new Date().getTimezoneOffset())).getTime() / 1000;
              // d.time = new Date(d.timestamp).getTime() / 1000;
              return d;
            });

          data = data.map((d) => {

            Object.keys(d).forEach(key => {
                if (!isNaN(d[key])) {
                    d[key] = +d[key];
                }
            });

            return {
              borderColor: d.color,
              wickColor: "#aaa",
              ...d,
            };
          });

          const markers = [];

          data
            .filter(
              (d) =>
                +d.oi_delta_percent_alert ||
                +d.price_delta_percent_alert ||
                +d.volume_delta_percent_alert ||
                +d.tight_range_alert ||
                +d.tight_consolidation_alert ||
                +d.rvol_alert
            )
            .forEach((d) => {
              if (+d.oi_delta_percent_alert !== 0) {
                markers.push({
                  time: d.time,
                  position:
                    +d.oi_delta_percent_alert < 0 ? "belowBar" : "aboveBar",
                  color: +d.oi_delta_percent_alert < 0 ? "red" : "blue",
                  shape: "circle",
                });
              }
              if (+d.price_delta_percent_alert !== 0) {
                markers.push({
                  time: d.time,
                  position:
                    +d.price_delta_percent_alert < 0 ? "belowBar" : "aboveBar",
                  color: +d.price_delta_percent_alert < 0 ? "red" : "blue",
                  shape: "square",
                });
              }
              if (+d.volume_delta_percent_alert !== 0) {
                markers.push({
                  time: d.time,
                  position:
                    +d.volume_delta_percent_alert < 0 ? "belowBar" : "aboveBar",
                  color: +d.volume_delta_percent_alert < 0 ? "red" : "blue",
                  shape:
                    +d.volume_delta_percent_alert < 0 ? "arrowDown" : "arrowUp",
                });
              }
              if (+d.tight_consolidation_alert !== 0) {
                markers.push({
                  time: d.time,
                  position: "aboveBar",
                  color: "yellow",
                  text: "C",
                });
              }
              // if (+d.tight_range_alert !== 0) {
              //   markers.push({
              //     time: d.time,
              //     position: "aboveBar",
              //     color: "yellow",
              //     text: "R",
              //   });
              // }
              if (+d.rvol_alert !== 0) {
                markers.push({
                  time: d.time,
                  position: "aboveBar",
                  color: "yellow",
                  text: "V",
                });
              }
            });

          // Add a marker to the candlestick series
          candlestickSeries.setMarkers(markers);

          candlestickSeries.setData(data); // Append new candlestick data

          if (cache.find((c) => c.symbol === symbol)) {
            const foundCache = cache.find((c) => c.symbol === symbol);
            foundCache.data = data;
            foundCache.markers = markers;
          } else {
            setCache([...cache, { symbol, data, markers }]);
          }
        });
    } catch (error) {
      console.error("Error fetching or parsing CSV:", error);
    }
  }

  useEffect(() => {
    if (cache.length > 1) {
      document.querySelector(".back-btn").style.display = "block";
    }
  }, [cache])

  function handleConvertToTimezone() {
    fetchData();
  }

  function handleBack() {
    if (cache.length > 0) {
      let data = cache.pop();

      let url = new URL(window.location.href);
      let params = url.searchParams;

      while (data.symbol === params.get("symbol")) {
        data = cache.pop();
      }

      if (cache.length < 1) {
        document.querySelector(".back-btn").style.display = "none";
      }

      if (cache.length === 0) {
        cache.push(data);
      }

      setCache(cache);

      document.getElementById("SymbolSelector").value = data.symbol;
      candlestickSeries.setMarkers(data.markers);
      candlestickSeries.setData(data.data);
      params.set('symbol', data.symbol);
      window.history.replaceState({}, '', url);
    } else {
      // fetchData();
    }
  }

  function handleSymbolChange() {
    let url = new URL(window.location.href);
    let params = url.searchParams;
    params.set("symbol", document.getElementById("SymbolSelector")?.value);
    window.history.replaceState({}, "", url);
    fetchData();
  }

  function parseCsvToJson(csvText) {
    const rows = csvText.split("\\n"); // Split by newline character
    const headers = rows[0].split(","); // Assume the first row has headers

    const jsonData = rows.slice(1).map((row) => {
      const values = row.split(",");
      return headers.reduce((obj, header, index) => {
        obj[header.trim()] = values[index]?.trim();
        return obj;
      }, {});
    });

    return jsonData;
  }

  function convertToTimezone(dateInput) {
    const timeZone = document.getElementById("timezoneSelect").value;

    if (!dateInput) {
      document.getElementById("output").innerText =
        "Please select a date and time.";
      return;
    }

    const localDate = new Date(dateInput);

    const options = {
      timeZone: timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      localDate
    );

    // Parse the formatted date back to a Date object
    const [month, day, year, hour, minute, second] = formattedDate
      .match(/\d+/g)
      .map(Number);
    const utcDate = new Date(
      Date.UTC(year, month - 1, day, hour, minute, second)
    );


    // return utcDate.setMinutes(utcDate.getMinutes() + new Date().getTimezoneOffset());
    return utcDate;
  }

  useEffect(() => {
    // Initialize the chart
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        background: {
          color: "#0f0f0f",
        },
        textColor: "#ffffff",
      },
      grid: {
        vertLines: { color: "#383938" },
        horzLines: { color: "#383938" },
      },
      crosshair: {
        mode: 1,
      },
      timeScale: {
        borderColor: "#cccccc",
        secondsVisible: false,
        timeVisible: true,
      },
      // Hide the right price scale to avoid conflicting alignment
      rightPriceScale: {
        visible: false,
        priceFormat: {
          type: "price",
          precision: 4, // Number of decimal places (e.g., 4 decimal places)
          minMove: 0.0001, // Smallest price change
        },
      },
      leftPriceScale: {
        visible: true, // Enable left price scale
        position: "left", // Position it explicitly on the left
        borderColor: "#cccccc",
        priceFormat: {
          type: "price",
          precision: 4, // Number of decimal places (e.g., 4 decimal places)
          minMove: 0.0001, // Smallest price change
        },
      },
    });

    // Add a candlestick series with the price scale set to 'left'
    const series = chart.addCandlestickSeries({
      upColor: "#4caf50",
      downColor: "#ef5350",
      borderUpColor: "#4caf50",
      borderDownColor: "#ef5350",
      wickUpColor: "#4caf50",
      wickDownColor: "#ef5350",
      priceScaleId: "left", // Attach this series to the left price scale
      priceFormat: {
        type: "price",
        precision: 4, // Number of decimal places (e.g., 4 decimal places)
        minMove: 0.0001, // Smallest price change
      }
    });

    setCandlestickSeries(series);

    return () => chart.remove();
  }, []);


  useEffect(() => {
    if (candlestickSeries) { 
      fetchData();
    }
  }, [candlestickSeries]);

  return (
    <div className="chart-holder mt-5 mx-auto py-10 px-6 container lightweight-chart-container">
      <div className="relative d-flex">
        <h2 id="Symbol">
          <button className="back-btn" onClick={handleBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              width="800px"
              height="800px"
              viewBox="0 0 24 24"
            >
              <g data-name="Layer 2">
                <g data-name="arrow-back">
                  <rect
                    width="24"
                    height="24"
                    transform="rotate(90 12 12)"
                    opacity="0"
                  />
                  <path d="M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23 1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2z" />
                </g>
              </g>
            </svg>
          </button>
          <select id="SymbolSelector" onChange={handleSymbolChange}></select>
          <button style={{ cursor: "pointer" }} onClick={openTVChart}>
            TV Chart
          </button>
        </h2>
        <div>
          <div
            className="timezone-selector"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <label htmlFor="timezoneSelect">Select Time Zone:</label>
              <br />
              <select id="timezoneSelect" onChange={handleConvertToTimezone}>
                <option value="UTC">UTC (Coordinated Universal Time)</option>
                <option value="America/New_York">
                  America/New_York (Eastern Time)
                </option>
                <option value="America/Chicago">
                  America/Chicago (Central Time)
                </option>
                <option value="America/Denver">
                  America/Denver (Mountain Time)
                </option>
                <option value="America/Los_Angeles">
                  America/Los_Angeles (Pacific Time)
                </option>
                <option value="America/Anchorage">
                  America/Anchorage (Alaska Time)
                </option>
                <option value="America/Honolulu">
                  America/Honolulu (Hawaii Time)
                </option>
                <option value="Europe/London">Europe/London (GMT)</option>
                <option value="Europe/Paris">
                  Europe/Paris (Central European Time)
                </option>
                <option value="Europe/Berlin">
                  Europe/Berlin (Central European Time)
                </option>
                <option value="Europe/Moscow">
                  Europe/Moscow (Moscow Time)
                </option>
                <option value="Asia/Tokyo">
                  Asia/Tokyo (Japan Standard Time)
                </option>
                <option value="Asia/Shanghai">
                  Asia/Shanghai (China Standard Time)
                </option>
                <option value="Asia/Singapore">
                  Asia/Singapore (Singapore Time)
                </option>
                <option value="Asia/Kolkata">
                  Asia/Kolkata (India Standard Time)
                </option>
                <option value="Asia/Dubai">
                  Asia/Dubai (Gulf Standard Time)
                </option>
                <option value="Asia/Riyadh">
                  Asia/Riyadh (Arabian Standard Time)
                </option>
                <option value="Asia/Seoul">
                  Asia/Seoul (Korea Standard Time)
                </option>
                <option value="Australia/Sydney">
                  Australia/Sydney (Australian Eastern Time)
                </option>
                <option value="Australia/Perth">
                  Australia/Perth (Australian Western Time)
                </option>
                <option value="Pacific/Auckland">
                  Pacific/Auckland (New Zealand Time)
                </option>
                <option value="America/Sao_Paulo">
                  America/Sao_Paulo (Brasilia Time)
                </option>
                <option value="Africa/Johannesburg">
                  Africa/Johannesburg (South Africa Time)
                </option>
                <option value="America/Mexico_City">
                  America/Mexico_City (Central Time)
                </option>
                <option value="Europe/Istanbul">
                  Europe/Istanbul (Turkey Time)
                </option>
                <option value="Asia/Bangkok">
                  Asia/Bangkok (Indochina Time)
                </option>
                <option value="America/Argentina/Buenos_Aires">
                  America/Argentina/Buenos_Aires (Argentina Time)
                </option>
                <option value="America/Toronto">
                  America/Toronto (Eastern Time)
                </option>
                <option value="Europe/Madrid">
                  Europe/Madrid (Central European Time)
                </option>
                <option value="Asia/Hong_Kong">
                  Asia/Hong_Kong (Hong Kong Time)
                </option>
                <option value="Asia/Jakarta">
                  Asia/Jakarta (Western Indonesia Time)
                </option>
                <option value="Asia/Kuala_Lumpur">
                  Asia/Kuala_Lumpur (Malaysia Time)
                </option>
                <option value="Asia/Manila">
                  Asia/Manila (Philippine Time)
                </option>
                <option value="Europe/Athens">
                  Europe/Athens (Eastern European Time)
                </option>
                <option value="Pacific/Honolulu">
                  Pacific/Honolulu (Hawaii-Aleutian Time)
                </option>
                <option value="Africa/Cairo">Africa/Cairo (Cairo Time)</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div id="chart" ref={chartContainerRef}></div>
      <div className="legends">
        <div className="legend">
          <div
            className="legend-image"
            style={{ display: "flex", alignItems: "center", color: "yellow" }}
          >
            C
          </div>
          Tight Consolidation Alert
        </div>
        <div className="legend">
          <div
            className="legend-image"
            style={{ display: "flex", alignItems: "center", color: "yellow" }}
          >
            V
          </div>
          RVOL Alert
        </div>
        </div>
        <div className="legends">
        <div className="legend">
          <div
            className="legend-image blue"
            style={{ backgroundImage: "url(/circle.png)" }}
          ></div>
          OI Delta Percent Positive
        </div>
        <div className="legend">
          <div
            className="legend-image blue"
            style={{ backgroundImage: "url(/square.png)" }}
          ></div>
          Volume Delta Percent Positive
        </div>
        <div className="legend">
          <div
            className="legend-image blue rotate-up"
            style={{ backgroundImage: "url(/triangle.png)" }}
          ></div>
          Price Delta Percent Positive
        </div>
      </div>
      <div className="legends">
        <div className="legend">
          <div
            className="legend-image"
            style={{ backgroundImage: "url(/circle.png)" }}
          ></div>
          OI Delta Percent Negative
        </div>
        <div className="legend">
          <div
            className="legend-image"
            style={{ backgroundImage: "url(/square.png)" }}
          ></div>
          Volume Delta Percent Negative
        </div>
        <div className="legend">
          <div
            className="legend-image"
            style={{ backgroundImage: "url(/triangle.png)" }}
          ></div>
          Price Delta Percent Negative
        </div>
      </div>
    </div>
  );
};
export default ChartComponent;

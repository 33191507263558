import React, { useState, useEffect } from "react";

const NotificationComponent = ({ show, message, onHide }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onHide();
      }, 3000); // Auto-hide after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [show, onHide]);

  return (
    <div
      className={`fixed bottom-4 right-4 transition-transform duration-500 ease-in-out transform ${
        show ? "translate-y-0 opacity-100" : "translate-y-8 opacity-0"
      } bg-green-500 text-white shadow-lg rounded-lg px-6 py-4 flex items-center space-x-3`}
      style={{ pointerEvents: show ? "auto" : "none" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <span>{message}</span>
    </div>
  );
};

export default NotificationComponent;
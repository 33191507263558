import http from './httpService'

const simulationConfigUrl = `${process.env.REACT_APP_API_URL}/simulations`

export function getSimulationConfig(simulationID) {
    return http.get(simulationConfigUrl, {params: {simulationID}})
}

export function getAllSimulationConfig(config) {
    return http.get(`${simulationConfigUrl}_all`)
}

export function postSimulationConfig(config) {
    return http.post(simulationConfigUrl, config)
}

export function getSimulationTradesHistory(simulationID) {
    return http.get(`${simulationConfigUrl}_trades_history`, {params: {simulationID}})
}
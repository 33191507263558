import axios from 'axios';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { fetchAuthSession } from "aws-amplify/auth";

import { isTokenExpired, refreshToken } from "./authService";

const tokenKey = "access_token";

axios.interceptors.request.use(
    async (config) => {
        let token = localStorage.getItem(tokenKey);

        if (!token || isTokenExpired(token)) {
           try {
              token = await refreshToken()
            } catch (error) {
                localStorage.removeItem(tokenKey);
                window.location = "/login";
                throw error;
            }
        }

        config.headers.Authorization = `Bearer ${token}`
        return config
    },
    (error) => {
      return Promise.reject(error);
    }
);

axios.interceptors.response.use(null, error => {
    const expectedError =
        error.response && 
        error.response.status >= 400 &
        error.response.status < 500;

    if (!expectedError) {
        toast.error("An unexpected error has occured!");
    }

    return Promise.reject(error);
});

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
}

import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./App.css";

import NavbarSimple from "./components/NavbarSimple";
import Configuration from "./components/Configuration";
import Login from "./components/Login";
import Logout from "./components/Logout";
import NotFound from "./components/NotFound";
import Profile from "./components/Profile";
import ProtectedRoute from "./components/ProtectedRoute";
import ProtectedRouteAdmin from "./components/ProtectedRouteAdmin";
import auth from "./services/authService";
import TradesComponent from "./components/Trades/Trades";
import ListComponent from "./components/List/List";
import ChartComponent from "./components/Chart/Chart";
import HomeComponent from "./components/Home";
import Simulate from "./components/Simulate";
import GGTradeCalc from "./components/GGTradeCalc";

function App() {
  const [user, setUser] = useState(auth.getCurrentUser());
  const [isAdmin, setIsAdmin] = useState(
    auth.getCurrentUser()
      ? auth.getCurrentUser()["cognito:groups"].indexOf("admin") > -1
      : false
  );

  return (
    <React.Fragment>
      <Router>
        {!user && (
          <Routes>
            <Route path="/login" element={<Login />} />
              <Route path="/" element={<HomeComponent />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        )}

        {user && (
          <React.Fragment>
            <NavbarSimple />
            <Routes>
              {isAdmin && (
                <>
                  <Route element={<ProtectedRouteAdmin />}>
                    <Route path="/exchange-config" element={<Configuration />} />
                  </Route>
                  <Route element={<ProtectedRouteAdmin />}>
                    <Route path="/simulate" element={<Simulate />} />
                  </Route>
                </>
              )}
              <Route element={<ProtectedRoute />}>
                <Route path="/calculator" element={<GGTradeCalc />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route path="/chart" element={<ChartComponent />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route path="/list" element={<ListComponent />} />
              </Route>
              {isAdmin && (
                <Route element={<ProtectedRouteAdmin />}>
                  <Route path="/trades" element={<TradesComponent />} />
                </Route>
              )}
              <Route element={<ProtectedRoute />}>
                <Route path="/profile" element={<Profile />} />
              </Route>
              <Route path="/logout" element={<Logout />} />
              <Route path="/not-found" element={<NotFound />} />
              <Route path="/login" element={<Navigate to="/" replace />} />
              <Route path="/" element={<Navigate to="/list" replace />} />
            </Routes>
          </React.Fragment>
        )}
      </Router>
    </React.Fragment>
  );
}

export default App;

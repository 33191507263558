import http from './httpService'

const exchangeConfigUrl = `${process.env.REACT_APP_API_URL}/exchange`

export function getExchangeConfig() {
    return http.get(exchangeConfigUrl)
}

export function updateExchangeConfig(config) {
    return http.put(exchangeConfigUrl, config)
}

import { Route, Navigate, Outlet } from "react-router-dom";
import auth from "../services/authService";

const ProtectedRoute = ({ redirectPath = "/logout", children }) => {
    const user = auth.getCurrentUser();

    const isAdmin = user['cognito:groups'].indexOf('admin') > -1;

    if (!user && isAdmin) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;

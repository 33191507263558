import React, { useState } from 'react';
import {
  Button,
  Input,
  Typography,
} from "@material-tailwind/react";
import QRCode from 'qrcode.react';
import { TailSpin } from 'react-loader-spinner';
import { Amplify } from "aws-amplify";
import { signIn, fetchAuthSession, setUpTOTP } from "aws-amplify/auth";

import awsconfig from '../aws-exports';

import { logIn, logOut, confirmNewPassword, confirmTOTP } from  "../services/authService";

Amplify.configure(awsconfig);

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [totpCode, setTotpCode] = useState('');
  const [totpSecret, setTotpSecret] = useState('');
  const [error, setError] = useState('');
  const [signInStep, setSignInStep] = useState('signIn');
  const [loading, setLoading] = useState(false); // State to control the loading spinner

  const handleSuccessfulSignIn = () => {
    window.location = "/";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start the loader
    try {
      const user = await logIn(username, password);
      setLoading(false); // Stop the loader
      if (user.isSignedIn) {
        handleSuccessfulSignIn();
      } else {
        setError("");
        if (user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
          setSignInStep('newPassword');
        } else if (user.nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_TOTP_SETUP") {
          const sharedSecret = user.nextStep.totpSetupDetails.sharedSecret;
          setTotpSecret(sharedSecret);
          setSignInStep('totpSetup');
        } else if (user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
          setSignInStep('totpCode');
        } else {
          setError("An unexpected login error has occurred");
        }
      }
    } catch (error) {
      setLoading(false); // Stop the loader on error
      if (error.name === "UserAlreadyAuthenticatedException") {
        await logOut();
        return;
      }
      setError(error.message);
      console.error("Error logging in:", error);
    }
  };

  const handleNewPasswordSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start the loader
    try {
      const user = await confirmNewPassword(newPassword);
      setLoading(false); // Stop the loader
      if (user.isSignedIn) {
        handleSuccessfulSignIn();
      } else if (user.nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_TOTP_SETUP") {
        const sharedSecret = user.nextStep.totpSetupDetails.sharedSecret;
        setTotpSecret(sharedSecret);
        setSignInStep('totpSetup');
      } else if (user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
        setSignInStep('totpCode');
      } else {
        setError("An unexpected login error has occurred");
      }
    } catch (err) {
      setLoading(false); // Stop the loader on error
      setError(err.message);
      console.error('Error setting new password:', err);
    }
  };

  const handleTotpSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start the loader
    try {
      const user = await confirmTOTP(totpCode);
      setLoading(false); // Stop the loader
      if (user.isSignedIn) {
        handleSuccessfulSignIn();
      } else {
        setError("An unexpected login error has occurred");
      }
    } catch (err) {
      setLoading(false); // Stop the loader on error
      setError(err.message);
      console.error('Error setting up TOTP:', err);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <TailSpin
            height="70"
            width="70"
            color="#b30222"
            ariaLabel="loading"
            visible={true}
          />
        </div>
      ) : (
        <form
          className="mt-14 mb-2 w-80 max-w-screen-lg sm:w-96 mx-auto"
          onSubmit={signInStep === 'signIn' ? handleSubmit : signInStep === 'newPassword' ? handleNewPasswordSubmit : handleTotpSubmit}
        >
          <div className="mb-1 flex flex-col gap-6">
            <Typography
              variant="h6"
              color="white"
              className="-mb-3"
            >
              {signInStep === 'signIn' ? 'Email' : signInStep === 'newPassword' ? 'New Password' : signInStep === 'totpSetup' ? 'Setup TOTP (2FA) by scanning the QR code' : 'Enter TOTP Code'}
            </Typography>
            {signInStep === 'signIn' ? (
              <>
                <Input
                  type="text"
                  name="email"
                  size="lg"
                  placeholder="Enter your email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                />
                <Typography
                  variant="h6"
                  color="white"
                  className="-mb-3"
                >
                  Password
                </Typography>
                <Input
                  type="password"
                  name="password"
                  size="lg"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                />
              </>
            ) : signInStep === 'newPassword' ? (
              <Input
                type="password"
                name="newPassword"
                size="lg"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
              />
            ) : signInStep === 'totpSetup' ? (
              <>
                {totpSecret && (
                  <div className="flex justify-center mb-6">
                    <QRCode
                      value={`otpauth://totp/${username}?secret=${totpSecret}&issuer=Crypto Exchange Config`}
                      size={180}
                      level={"H"}
                      includeMargin={true}
                    />
                  </div>
                )}
                <Input
                  type="text"
                  name="totpCode"
                  size="lg"
                  placeholder="Enter your TOTP code"
                  value={totpCode}
                  onChange={(e) => setTotpCode(e.target.value)}
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
                />
              </>
            ) : (
              <Input
                type="text"
                name="totpCode"
                size="lg"
                placeholder="Enter your TOTP code"
                value={totpCode}
                onChange={(e) => setTotpCode(e.target.value)}
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                className="w-full placeholder:opacity-100 text-white !border-t-blue-gray-200 focus:!border-red-500"
              />
            )}
          </div>
          {error && <Typography color="red" className="mt-2 text-center font-normal">{error}</Typography>}
          <Button className="mt-6" style={{ backgroundColor: "#b30222" }} fullWidth type="submit" disabled={loading}>
            {signInStep === 'signIn' ? 'Log In' : signInStep === 'newPassword' ? 'Set New Password' : 'Submit TOTP'}
          </Button>
          {signInStep === 'signIn' && (
            <Typography color="gray" className="mt-4 text-center font-normal" style={{ color: "#aaaaaa" }}>
              Don't have an account?{" "}
              <a href="#" className="font-medium text-white">
                Sign Up
              </a>
            </Typography>
          )}
        </form>
      )}
    </>
  );
};

export default Login;

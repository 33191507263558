import React from "react";

const HomeComponent = () => {
  return (
    <div
      style={{
        backgroundImage: "url('/bg.jpg')", // Replace with your background image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* Header Section */}
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          position: "relative",
          zIndex: 1,
        }}
      >
        <div
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={"https://d12142f10735pf.cloudfront.net/logo.webp"}
            style={{ borderRadius: "10px", width: "50px", marginRight: "10px" }}
          />
          AI Trader Signals
        </div>
        <a
          href="/login"
          style={{
            backgroundColor: "transparent",
            border: "1px solid white",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Login
        </a>
      </header>

      {/* Main Content Section */}
      <main
        style={{
          textAlign: "center",
          marginTop: "-50px",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textShadow: "0px 0px 4px rgba(0,0,0,0.4)"
        }}
      >
        <h1 style={{ fontSize: "48px", marginBottom: "10px" }}>
          AI Trader Signals
        </h1>
        <p style={{ fontSize: "24px" }}>Quality You Can Trust.</p>
      </main>
      <footer
        style={{
          padding: "20px",
          background: "rgba(0, 0, 0, 0.9)",
          textAlign: "center",
        }}
      >
        Copyright © 2024 AI Trader Signals - All Rights Reserved.
      </footer>
    </div>
  );
};

export default HomeComponent;

import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Grid } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Trades.css"

const TradesComponent = ({trades}) => {
  const initialized = useRef(false);
  const gridRef = useRef(null);

  const [tableInitialized, setTableInitialized] = useState(false);
  const [globalParams, setGlobalParams] = useState(null);

  function exportVisibleColumnsToCSV() {
    // Get the visible columns
    const visibleColumnKeys = globalParams.api
        .getAllDisplayedColumns()
        .map(col => col.getColId());

    // Export the data
    globalParams.api.exportDataAsCsv({
        columnKeys: visibleColumnKeys, // Export only visible columns
    });
  }

  function exportDefinedColumnsToCSV() {
    // Get the visible columns
    const visibleColumnKeys = ['symbol', 'tp_percent', 'sl_percent', 'tp_price', 'sl_price', 'signal["description"]', 'side', 'status', 'duration', 'entry_price', 'exit_price', 'entry_datetime', 'exit_datetime', 'exit_type', 'net_pnl', 'leverage', 'projected_rr_net'];

    // Export the data
    globalParams.api.exportDataAsCsv({
        columnKeys: visibleColumnKeys, // Export only visible columns
    });
  }

  function fetchData(params) {
    if (trades) {
      setTimeout(() => {
        const columnDefs = [];
        if (trades && trades[0] && params) {
          if (!tableInitialized) {
            trades.forEach((element) => {
              Object.keys(element).forEach((key) => {
                if (!columnDefs.find((k) => k.field === key)) {
                  columnDefs.push({
                    field: key,
                    headerName: capitalizeEachWord(key.split("_").join(" ")),
                  });
                }
              });
            });
            params.api.setGridOption("columnDefs", columnDefs); // Set the data using params.api
          }
          setGlobalParams(params);
          params.api.setGridOption("rowData", trades);
          params.api.autoSizeAllColumns();
          setTableInitialized(true);
        }
      });
    } else {
      let url = new URL(window.location.href);
      let urlParams = url.searchParams;
  
      const reqParams = new URLSearchParams({
        symbol: urlParams.get("symbol"),
        from_timestamp: urlParams.get("from_timestamp"),
        to_timestamp: urlParams.get("to_timestamp"),
      });
  
      if (reqParams.get("symbol") === "null") {
        reqParams.delete("symbol");
      }
      if (reqParams.get("from_timestamp") === "null") {
        reqParams.delete("from_timestamp");
      }
      if (reqParams.get("to_timestamp") === "null") {
        reqParams.delete("to_timestamp");
      }
  
      fetch(
        "https://j444xz2iu5oaxlrni7o264asgq0kbvks.lambda-url.us-east-1.on.aws/trades?" +
          reqParams.toString(),
        {
          headers: {
            Authorization: "LkAFZemOSK2OU8",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          const columnDefs = [];
          if (res && res[0]) {
            if (!tableInitialized) {
              res.forEach((element) => {
                Object.keys(element).forEach((key) => {
                  if (!columnDefs.find((k) => k.field === key)) {
                    columnDefs.push({
                      field: key,
                      headerName: capitalizeEachWord(key.split("_").join(" ")),
                    });
                  }
                });
              });
              params.api.setGridOption("columnDefs", columnDefs); // Set the data using params.api
            }
            setGlobalParams(params);
            params.api.setGridOption("rowData", res);
            params.api.autoSizeAllColumns();
            setTableInitialized(true);
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  }

  // Function to set row data once the grid is ready
  function onGridReady(params) {
    fetchData(params);
    if (!trades) {
      setInterval(() => {
        fetchData(params);
      }, 60000);
    }
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const gridOptions = {
        columnDefs: [],
        defaultColDef: {
          resizable: true,
          filter: true,
          sortable: true,
        },
        pagination: true,
        paginationPageSize: 50,
        animateRows: true,
        onGridReady: onGridReady, // Add the onGridReady event
      };

      new Grid(gridRef.current, gridOptions);
    }
  }, []);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const gridOptions = {
        columnDefs: [],
        defaultColDef: {
          resizable: true,
          filter: true,
          sortable: true,
        },
        pagination: true,
        paginationPageSize: 50,
        animateRows: true,
        onGridReady: onGridReady, // Add the onGridReady event
      };

      new Grid(gridRef.current, gridOptions);
    } else if (globalParams) {
      fetchData(globalParams);
    }
  }, [trades]);

  return (
    <div className="trades-grid">
      <button onClick={exportVisibleColumnsToCSV} id="exportVisButton">
        Export Visible Columns Data
      </button>
      <button onClick={exportDefinedColumnsToCSV} id="exportButton">
        Export Defined Columns
      </button>
      <br />
      <div
        id="myGrid"
        ref={gridRef}
        className="ag-theme-alpine-dark"
        style={{ height: "calc(100vh - 160px)", width: "100%" }}
      ></div>
    </div>
  );
};

function capitalizeEachWord(str) {
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

export default TradesComponent;

import { useState } from "react";
import NotificationComponent from "./NotificationComponent";

const GGTradeCalc = () => {
  const [config, setConfig] = useState({
    vui1_bp: 1,
    sl_bp: 1,
    vui2_bp: 1,
    sl_sl: 1,
    vui1_l: 1,
    vui2_l: 1,
    vui1_m: 1,
    sl_m: 1,
    vui2_m: 1,
    sl_liq: 1,
  });
  const [showNotification, setShowNotification] = useState(false);

  const handleShowNotification = () => {
    setShowNotification(true);
  };

  const handleHideNotification = () => {
    setShowNotification(false);
  };

  const handleChange = (key, value) => {
    if (
      !isNaN(+value) &&
      value[0] !== "." &&
      value[value.length - 1] !== "." &&
      value !== "" &&
      value != 0
    ) {
      value = +value;
    }
    setConfig((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const convertNaN = (value, decimalPoints = 0) => {
    if (isNaN(value) || value === '0') {
      return 0;
    }
    if (!decimalPoints) {
      return +value;
    } else {
      console.log(value);
      return +value.toFixed(decimalPoints);
    }
  }

  const copyValue = (e) => {
    const processedText = e.target.innerText.replace("$", "").replace("%", "");
    navigator.clipboard
      .writeText(processedText)
      .then(() => {
        handleShowNotification();
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: 50,
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <table className="native-table"
          style={{ borderSpacing: '10px 0', borderCollapse: 'initial' }}
          >
            <thead>
              <tr>
                <th colSpan={2}>
                  <h2>Long</h2>
                </th>
                <th></th>
                <th colSpan={2}>
                  <h2>Short</h2>
                </th>
              </tr>
              <tr>
                <th>Parameter</th>
                <th>Value (User Input)</th>
                <th>SL (stop loss)</th>
                <th>Parameter</th>
                <th>Value (User Input)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>Buy Price</td>
                <td className="table-input">
                  $
                  <input
                    onFocus={e => e.target.select()}
                    onChange={(e) => handleChange("vui1_bp", e.target.value)}
                    value={config.vui1_bp}
                  />
                </td>
                <td className="table-input">
                  <input
                    onFocus={e => e.target.select()}
                    onChange={(e) => handleChange("sl_bp", e.target.value)}
                    value={config.sl_bp}
                  />
                  %
                </td>
                <td style={{ fontWeight: "bold" }}>Buy Price</td>
                <td className="table-input">
                  $
                  <input
                    onFocus={e => e.target.select()}
                    onChange={(e) => handleChange("vui2_bp", e.target.value)}
                    value={config.vui2_bp}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Sell Price</td>
                <td className="cursor-pointer" onClick={(e) => copyValue(e)}>
                  ${convertNaN((config.vui1_bp + config.vui1_bp * (config.sl_sl / 100)))
                  }
                </td>
                <td style={{ fontWeight: "bold" }}>% Price Movement (TV)</td>
                <td style={{ fontWeight: "bold" }}>Sell Price</td>
                <td className="cursor-pointer" onClick={(e) => copyValue(e)}>
                  ${convertNaN((config.vui2_bp - config.vui2_bp * (config.sl_sl / 100)))
                  }
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Stop Loss</td>
                <td
                  className="indicator cursor-pointer"
                  onClick={(e) => copyValue(e)}
                  style={{
                    background:
                      config.vui1_bp - config.vui1_bp * (config.sl_bp / 100) <
                      (config.vui1_bp -
                        (config.vui1_m - config.vui1_m * config.vui1_l * 0.005) /
                          ((config.vui1_m * config.vui1_l) / config.vui1_bp))
                        ? "red"
                        : "",
                  }}
                >
                  ${convertNaN((config.vui1_bp - config.vui1_bp * (config.sl_bp / 100)))
                  }
                </td>
                <td className="table-input">
                  <input
                    onFocus={e => e.target.select()}
                    onChange={(e) => handleChange("sl_sl", e.target.value)}
                    value={config.sl_sl}
                  />
                  %
                </td>
                <td style={{ fontWeight: "bold" }}>Stop Loss</td>
                <td
                  className="indicator cursor-pointer"
                  onClick={(e) => copyValue(e)}
                  style={{
                    background:
                      config.vui2_bp + config.vui2_bp * (config.sl_bp / 100) >
                      (config.vui2_bp +
                        (config.vui2_m - config.vui2_m * config.vui2_l * 0.005) /
                          ((config.vui2_m * config.vui2_l) / config.vui2_bp))
                        ? "red"
                        : "",
                  }}
                >
                  ${convertNaN((config.vui2_bp + config.vui2_bp * (config.sl_bp / 100)))
                  }
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Leverage</td>
                <td className="table-input">
                  <input
                    onFocus={e => e.target.select()}
                    onChange={(e) => handleChange("vui1_l", e.target.value)}
                    value={config.vui1_l}
                  />
                </td>
                <td style={{ fontWeight: "bold" }}>Trading fees</td>
                <td style={{ fontWeight: "bold" }}>Leverage</td>
                <td className="table-input">
                  <input
                    onFocus={e => e.target.select()}
                    onChange={(e) => handleChange("vui2_l", e.target.value)}
                    value={config.vui2_l}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Margin (USDT)</td>
                <td className="table-input">
                  $
                  <input
                    onFocus={e => e.target.select()}
                    onChange={(e) => handleChange("vui1_m", e.target.value)}
                    value={config.vui1_m}
                  />
                </td>
                <td className="table-input">
                  <input
                    onFocus={e => e.target.select()}
                    onChange={(e) => handleChange("sl_m", e.target.value)}
                    value={config.sl_m}
                  />
                  %
                </td>
                <td style={{ fontWeight: "bold" }}>Margin (USDT)</td>
                <td className="table-input">
                  $
                  <input
                    onFocus={e => e.target.select()}
                    onChange={(e) => handleChange("vui2_m", e.target.value)}
                    value={config.vui2_m}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Liquidation</td>
                <td className="cursor-pointer" onClick={(e) => copyValue(e)}>
                  $
                  {convertNaN((config.vui1_bp -
                    (config.vui1_m - config.vui1_m * config.vui1_l * 0.005) /
                      ((config.vui1_m * config.vui1_l) / config.vui1_bp)), 8)
                      }
                </td>
                <td style={{ fontWeight: "bold" }}>Margin fees</td>
                <td style={{ fontWeight: "bold" }}>Liquidation</td>
                <td className="cursor-pointer" onClick={(e) => copyValue(e)}>
                  $
                  {convertNaN((config.vui2_bp +
                    (config.vui2_m - config.vui2_m * config.vui2_l * 0.005) /
                      ((config.vui2_m * config.vui2_l) / config.vui2_bp)), 8)
                      }
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Liq % below Entry</td>
                <td className="cursor-pointer" onClick={(e) => copyValue(e)}>
                  {convertNaN((((config.vui1_bp -
                    (config.vui1_bp -
                      (config.vui1_m - config.vui1_m * config.vui1_l * 0.005) /
                        ((config.vui1_m * config.vui1_l) / config.vui1_bp))) /
                    config.vui1_bp) *
                    100), 3)
                    }
                  %
                </td>
                <td className="table-input">
                  <input
                    onFocus={e => e.target.select()}
                    onChange={(e) => handleChange("sl_liq", e.target.value)}
                    value={config.sl_liq}
                  />
                  %
                </td>
                <td style={{ fontWeight: "bold" }}>Liq % below Entry</td>
                <td className="cursor-pointer" onClick={(e) => copyValue(e)}>
                  {convertNaN((((config.vui2_bp -
                    (config.vui2_bp +
                      (config.vui2_m - config.vui2_m * config.vui2_l * 0.005) /
                        ((config.vui2_m * config.vui2_l) / config.vui2_bp))) /
                    config.vui2_bp) *
                    100), 3)
                    }
                  %
                </td>
              </tr>
              <tr>
                <td>
                Position Size
                </td>
                <td>
                  {convertNaN((config.vui1_l * config.vui1_m))
                  }
                </td>
                <td></td>
                <td>
                Position Size
                </td>
                <td>
                  {convertNaN((config.vui2_l * config.vui2_m))
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>




<div style={{width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: 50,
          gap: "10px",
        }}>



        <div style={{ width: "50%" }}>
          <table className="native-table">
            <thead>
              <tr>
                <th colSpan={2}>Long Win</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td>Price Change %</td>
              <td>
                {convertNaN((!(config.vui1_bp + config.vui1_bp * (config.sl_sl / 100))
                  ? config.vui1_bp - config.vui1_bp * (config.sl_bp / 100)
                  : ((config.vui1_bp +
                      config.vui1_bp * (config.sl_sl / 100) -
                      config.vui1_bp) /
                      config.vui1_bp) *
                    100), 3)
                    }%
              </td>
              </tr>
              <tr>
                <td>
                Gross Profit
                </td>
                <td>
                  ${convertNaN(((!(config.vui1_bp + config.vui1_bp * (config.sl_sl / 100))
                  ? config.vui1_bp - config.vui1_bp * (config.sl_bp / 100)
                  : ((config.vui1_bp +
                      config.vui1_bp * (config.sl_sl / 100) -
                      config.vui1_bp) /
                      config.vui1_bp) *
                    100) * (config.vui1_l * config.vui1_m) / 100), 3)
                    }
                </td>
              </tr>
              <tr>
                <td>
                Bybit Trading Fees
                </td>
                <td>
                  ${convertNaN((2 * config.sl_m * (config.vui1_l * config.vui1_m) / 100), 8)
                  }
                </td>
              </tr>
              <tr>
                <td>
                Margin Funding Fees
                </td>
                <td>
                ${convertNaN((config.sl_m * (config.vui1_l * config.vui1_m) / 100), 8)
                }
                </td>
              </tr>
              <tr>
                <td>
                Total Fees
                </td>
                <td>
                  ${convertNaN(((config.sl_m * (config.vui1_l * config.vui1_m) / 100) * 3), 8)
                  }
                </td>
              </tr>
              <tr>
                <td>
                Net Profit
                </td>
                <td>
                  ${convertNaN((((!(config.vui1_bp + config.vui1_bp * (config.sl_sl / 100))
                  ? config.vui1_bp - config.vui1_bp * (config.sl_bp / 100)
                  : ((config.vui1_bp +
                      config.vui1_bp * (config.sl_sl / 100) -
                      config.vui1_bp) /
                      config.vui1_bp) *
                    100) * (config.vui1_l * config.vui1_m) / 100) - ((config.sl_m * (config.vui1_l * config.vui1_m) / 100) * 3)), 8)
                    }
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={2}>Long Loss</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                Price Change %
                </td>
                <td>
                  {convertNaN((-(config.vui1_bp - (config.vui1_bp - config.vui1_bp * (config.sl_bp / 100))) / (config.vui1_bp - config.vui1_bp * (config.sl_bp / 100)) * 100), 3)
                  }%
                </td>
              </tr>
              <tr>
                <td>
                Gross Loss
                </td>
                <td>
                  ${convertNaN((Math.abs((-(config.vui1_bp - (config.vui1_bp - config.vui1_bp * (config.sl_bp / 100))) / (config.vui1_bp - config.vui1_bp * (config.sl_bp / 100)) * 100) * (config.vui1_l * config.vui1_m) / 100)), 2)
                  }
                </td>
              </tr>
              <tr>
                <td>
                Bybit Trading Fees
                </td>
                <td>
                  ${convertNaN((config.sl_m * config.vui1_l * config.vui1_m * 2 / 100), 8)
                  }
                </td>
              </tr>
              <tr>
                <td>
                Margin Funding Fees
                </td>
                <td>
                  ${convertNaN((config.sl_liq * config.vui1_l * config.vui1_m * 2 / 100), 8)
                  }
                </td>
              </tr>
              <tr>
                <td>
                  Total Fees
                </td>
                <td>
                  ${convertNaN(((config.sl_m * config.vui1_l * config.vui1_m * 2 / 100) + config.sl_liq * config.vui1_l * config.vui1_m * 2 / 100), 8)
                  }
                </td>
              </tr>
              <tr>
                <td>
                  Net Loss
                </td>
                <td>
                  ${convertNaN(((Math.abs((-(config.vui1_bp - (config.vui1_bp - config.vui1_bp * (config.sl_bp / 100))) / (config.vui1_bp - config.vui1_bp * (config.sl_bp / 100)) * 100) * (config.vui1_l * config.vui1_m) / 100)) + ((config.sl_m * config.vui1_l * config.vui1_m * 2 / 100) + config.sl_liq * config.vui1_l * config.vui1_m * 2 / 100)), 2)
                  }
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={2}>
                Risk/Reward	
                </th>
              </tr>
              <tr>
                <td>
                RR Gross
                </td>
                <td>
                  {
                  // convertNaN((Math.abs(((!(config.vui1_bp + config.vui1_bp * (config.sl_sl / 100))
                  // ? config.vui1_bp - config.vui1_bp * (config.sl_bp / 100)
                  // : ((config.vui1_bp +
                  //     config.vui1_bp * (config.sl_sl / 100) -
                  //     config.vui1_bp) /
                  //     config.vui1_bp) *
                  //   100) * (config.vui1_l * config.vui1_m) / 100) 
                  //   / 
                  //   (Math.abs((-(config.vui1_bp - (config.vui1_bp - config.vui1_bp * (config.sl_bp / 100))) / (config.vui1_bp - config.vui1_bp * (config.sl_bp / 100)) * 100) * (config.vui1_l * config.vui1_m) / 100)))), 8)
                  convertNaN(config.sl_bp / config.sl_sl, 8)
                    }:1
                </td>
              </tr>
              <tr>
                <td>
                RR Net
                </td>
                <td>
                  <div>
                    {
                      convertNaN(((((!(config.vui1_bp + config.vui1_bp * (config.sl_sl / 100))
                  ? config.vui1_bp - config.vui1_bp * (config.sl_bp / 100)
                  : ((config.vui1_bp +
                      config.vui1_bp * (config.sl_sl / 100) -
                      config.vui1_bp) /
                      config.vui1_bp) *
                    100) * (config.vui1_l * config.vui1_m) / 100) - ((config.sl_m * (config.vui1_l * config.vui1_m) / 100) * 3))
                      /
                      ((Math.abs((-(config.vui1_bp - (config.vui1_bp - config.vui1_bp * (config.sl_bp / 100))) / (config.vui1_bp - config.vui1_bp * (config.sl_bp / 100)) * 100) * (config.vui1_l * config.vui1_m) / 100)) + ((config.sl_m * config.vui1_l * config.vui1_m * 2 / 100) + config.sl_liq * config.vui1_l * config.vui1_m * 2 / 100))), 3)
                    }:1
                  </div>
                </td>
              </tr>
            </thead>
            </table>
        </div>





        
        <div style={{ width: "50%" }}>
          <table className="native-table">
            <thead>
              <tr>
                <th colSpan={2}>Short Win</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td>Price Change %</td>
              <td>
                {convertNaN((!(config.vui2_bp + config.vui2_bp * (config.sl_sl / 100))
                  ? config.vui2_bp - config.vui2_bp * (config.sl_bp / 100)
                  : ((
                    config.vui2_bp - (config.vui2_bp +
                      config.vui2_bp * (config.sl_sl / 100))) /
                      config.vui2_bp) *
                    100), 3)
                    }%
              </td>
              </tr>
              <tr>
                <td>
                Gross Profit
                </td>
                <td>
                  ${convertNaN(((!(config.vui2_bp + config.vui2_bp * (config.sl_sl / 100))
                  ? config.vui2_bp - config.vui2_bp * (config.sl_bp / 100)
                  : ((config.vui2_bp +
                      config.vui2_bp * (config.sl_sl / 100) -
                      config.vui2_bp) /
                      config.vui2_bp) *
                    100) * (config.vui2_l * config.vui2_m) / 100), 8)
                    }
                </td>
              </tr>
              <tr>
                <td>
                Bybit Trading Fees
                </td>
                <td>
                  ${convertNaN((2 * config.sl_m * (config.vui2_l * config.vui2_m) / 100), 8)
                  }
                </td>
              </tr>
              <tr>
                <td>
                Margin Funding Fees
                </td>
                <td>
                ${convertNaN((config.sl_m * (config.vui2_l * config.vui2_m) / 100), 8)
                }
                </td>
              </tr>
              <tr>
                <td>
                Total Fees
                </td>
                <td>
                  ${convertNaN(((config.sl_m * (config.vui2_l * config.vui2_m) / 100) * 3), 8)
                  }
                </td>
              </tr>
              <tr>
                <td>
                Net Profit
                </td>
                <td>
                  ${convertNaN((((!(config.vui2_bp + config.vui2_bp * (config.sl_sl / 100))
                  ? config.vui2_bp - config.vui2_bp * (config.sl_bp / 100)
                  : ((config.vui2_bp +
                      config.vui2_bp * (config.sl_sl / 100) -
                      config.vui2_bp) /
                      config.vui2_bp) *
                    100) * (config.vui2_l * config.vui2_m) / 100) - ((config.sl_m * (config.vui2_l * config.vui2_m) / 100) * 3)), 8)
                    }
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={2}>Short Loss</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                Price Change %
                </td>
                <td>
                  {convertNaN((((((config.vui2_bp + config.vui2_bp * (config.sl_bp / 100)) - (config.vui2_bp)) / (config.vui2_bp + config.vui2_bp * (config.sl_bp / 100)))) * 100), 3)
                  }%
                </td>
              </tr>
              <tr>
                <td>
                Gross Loss
                </td>
                <td>
                  ${convertNaN(((((((config.vui2_bp + config.vui2_bp * (config.sl_bp / 100)) - (config.vui2_bp)) / (config.vui2_bp + config.vui2_bp * (config.sl_bp / 100))))) * (config.vui2_l * config.vui2_m)), 2)
                  }
                </td>
              </tr>
              <tr>
                <td>
                Bybit Trading Fees
                </td>
                <td>
                  ${convertNaN((config.sl_m * config.vui2_l * config.vui2_m * 2 / 100), 8)
                  }
                </td>
              </tr>
              <tr>
                <td>
                Margin Funding Fees
                </td>
                <td>
                  ${convertNaN((config.sl_liq * config.vui2_l * config.vui2_m * 2 / 100), 8)
                  }
                </td>
              </tr>
              <tr>
                <td>
                  Total Fees
                </td>
                <td>
                  ${convertNaN(((config.sl_m * config.vui2_l * config.vui2_m * 2 / 100) + config.sl_liq * config.vui2_l * config.vui2_m * 2 / 100), 8)
                  }
                </td>
              </tr>
              <tr>
                <td>
                  Net Loss
                </td>
                <td>
                  ${convertNaN(((((((config.vui2_bp + config.vui2_bp * (config.sl_bp / 100)) - (config.vui2_bp)) / (config.vui2_bp + config.vui2_bp * (config.sl_bp / 100))))) * (config.vui2_l * config.vui2_m) + ((config.sl_m * config.vui2_l * config.vui2_m * 2 / 100) + config.sl_liq * config.vui2_l * config.vui2_m * 2 / 100)), 2)
                  }
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={2}>
                Risk/Reward	
                </th>
              </tr>
              <tr>
                <td>
                RR Gross
                </td>
                <td>
                  {
                    // convertNaN((((!(config.vui2_bp + config.vui2_bp * (config.sl_sl / 100))
                    // ? config.vui2_bp - config.vui2_bp * (config.sl_bp / 100)
                    // : ((config.vui2_bp +
                    //     config.vui2_bp * (config.sl_sl / 100) -
                    //     config.vui2_bp) /
                    //     config.vui2_bp) *
                    //   100) * (config.vui2_l * config.vui2_m) / 100)
                    //   /
                    //   ((((((config.vui2_bp + config.vui2_bp * (config.sl_bp / 100)) - (config.vui2_bp)) / (config.vui2_bp + config.vui2_bp * (config.sl_bp / 100))))) * (config.vui2_l * config.vui2_m))), 8)
                  convertNaN(config.sl_bp / config.sl_sl, 8)
                  }:1
                </td>
              </tr>
              <tr>
                <td>
                RR Net
                </td>
                <td>
                  <div>
                    {
                      convertNaN(((((!(config.vui2_bp + config.vui2_bp * (config.sl_sl / 100))
                      ? config.vui2_bp - config.vui2_bp * (config.sl_bp / 100)
                      : ((config.vui2_bp +
                          config.vui2_bp * (config.sl_sl / 100) -
                          config.vui2_bp) /
                          config.vui2_bp) *
                        100) * (config.vui2_l * config.vui2_m) / 100) - ((config.sl_m * (config.vui2_l * config.vui2_m) / 100) * 3))
                        /
                        ((((((config.vui2_bp + config.vui2_bp * (config.sl_bp / 100)) - (config.vui2_bp)) / (config.vui2_bp + config.vui2_bp * (config.sl_bp / 100))))) * (config.vui2_l * config.vui2_m) + ((config.sl_m * config.vui2_l * config.vui2_m * 2 / 100) + config.sl_liq * config.vui2_l * config.vui2_m * 2 / 100))), 3)
                    }:1
                  </div>
                </td>
              </tr>
            </thead>
            </table>
        </div>

</div>

      </div>
      <NotificationComponent
        show={showNotification}
        message="Value copied to clipboard!"
        onHide={handleHideNotification}
      />
    </>
  );
};

export default GGTradeCalc;

import { jwtDecode } from "jwt-decode";
import { signIn, signOut, confirmSignIn, fetchAuthSession, setUpTOTP } from "aws-amplify/auth"


const tokenKey= "access_token";

export async function logIn(username, password) {
    try {
        const user = await signIn({username: username, password: password});
        if (user.isSignedIn) {
            const { tokens: { accessToken: decodedToken } } = await fetchAuthSession();
            const token = decodedToken.toString()
            localStorage.setItem("access_token", token);
        }
        return user;
    } catch (error) {
        throw error;
    }
}

export async function logOut() {
    await signOut();
    localStorage.removeItem(tokenKey);
}

export async function confirmNewPassword(password) {
  try {
      const user = await confirmSignIn({challengeResponse: password});
      if (user.isSignedIn) {
          const { tokens: { accessToken: decodedToken } } = await fetchAuthSession();
          const token = decodedToken.toString()
          localStorage.setItem("access_token", token);
      }
      return user;
  } catch (error) {
      throw error;
  }
}

export async function confirmTOTP(totpCode) {
  try {
      const user = await confirmSignIn({challengeResponse: totpCode});
      if (user.isSignedIn) {
          const { tokens: { accessToken: decodedToken } } = await fetchAuthSession();
          const token = decodedToken.toString()
          localStorage.setItem("access_token", token);
      }
      return user;
  } catch (error) {
      throw error;
  }
}

export async function refreshToken() {
    /* TODO: handle the case when the refresh fails due to refresh token expiration*/
    const { tokens: { accessToken: decodedToken } } = await fetchAuthSession();
    const token = decodedToken.toString();
    localStorage.setItem(tokenKey, token);
    return token;
}

export function isTokenExpired(token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime
}

export function getCurrentUser() {
    try {
        const token = localStorage.getItem(tokenKey);
        return jwtDecode(token);
    } catch (error) {
        return null;
    }
}

export function getToken() {
    return localStorage.getItem(tokenKey);
}

export default {
  logIn,
  logOut,
  refreshToken,
  isTokenExpired,
  getCurrentUser,
  getToken
}

import { NavLink } from 'react-router-dom';
import {
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Typography,
} from "@material-tailwind/react";
 
import { MdAccountCircle } from "react-icons/md";

export default function ProfileMenu() {
  return (
    <Menu>
      <MenuHandler>
      <Typography
        as="li"
        variant="h6"
        className="p-1 font-medium"
      >
        <Button className="p-2 transparcwent" style={{background: "transparent"}}>
        <a href="#" className="flex items-center hover:text-gray-500 transition-colors">
          <MdAccountCircle size={30}/>
        </a>
        </Button>
      </Typography>
      </MenuHandler>
      <MenuList className="bg-black text-white" style={{borderRadius: "100px"}}>
          <NavLink to="/profile" className="outline-none">
            <MenuItem className="flex items-center gap-2 focus:outline-none">
              <Typography variant="small" className="font-medium">
                My Profile
              </Typography>
            </MenuItem>
          </NavLink>
        <hr className="my-2 border-blue-gray-50" />
          <NavLink to="/logout" className="outline-none">
            <MenuItem className="flex items-center gap-2 focus:outline-none">
              <Typography variant="small" className="font-medium">
                Log Out
              </Typography>
            </MenuItem>
          </NavLink>
      </MenuList>
    </Menu>
  );
}


import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Navbar,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { RiExchangeDollarFill } from "react-icons/ri";
import { MdAccountCircle } from "react-icons/md";
import auth from "../services/authService";

import ProfileMenu from "./ProfileMenu";

function NavList() {
  return (
    <ul className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <ProfileMenu />
    </ul>
  );
}

const NavbarSimple = () => {
  const [isAdmin, setIsAdmin] = useState(
    auth.getCurrentUser()
      ? auth.getCurrentUser()["cognito:groups"].indexOf("admin") > -1
      : false
  );

  const [openNav, setOpenNav] = useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Navbar
      className="w-full py-5 rounded-none border-none shadow-none bg-primary"
      style={{ maxWidth: "100%", backgroundColor: "#070707", color: "#eeeeee" }}
    >
      <div className="flex items-center justify-between">
        <NavLink to="/" className="outline-none">
          <div className="flex items-center justify-between">
            {/* <RiExchangeDollarFill
              className="inline mr-2"
              size={60}
              color="#b30222"
            /> */}
          <img
            src={"https://d12142f10735pf.cloudfront.net/logo.webp"}
            style={{ borderRadius: "10px", width: "50px", marginRight: "10px" }}
          />
            <Typography
              as="a"
              href="#"
              variant="h5"
              className="mr-3 cursor-pointer py-1.5"
            >
              AI Trader Signals
            </Typography>
          </div>
        </NavLink>
        {/* Add the additional NavLink items here */}
        <div className="flex items-center space-x-6">
          <NavLink
            to="/chart"
            className="text-white hover:text-gray-400 cursor-pointer py-1.5"
          >
            CHART
          </NavLink>
          <NavLink
            to="/list"
            className="text-white hover:text-gray-400 cursor-pointer py-1.5"
          >
            SCANNER
          </NavLink>
          {isAdmin && (
            <>
              <NavLink
                to="/trades"
                className="text-white hover:text-gray-400 cursor-pointer py-1.5"
              >
                TRADES
              </NavLink>
              <NavLink
                to="/exchange-config"
                className="text-white hover:text-gray-400 cursor-pointer py-1.5"
              >
                SETTINGS
              </NavLink>
              <NavLink
                to="/simulate"
                className="text-white hover:text-gray-400 cursor-pointer py-1.5"
              >
                SIMULATE
              </NavLink>
            </>
          )}
          <NavLink
            to="/calculator"
            className="text-white hover:text-gray-400 cursor-pointer py-1.5"
          >
            CALCULATOR
          </NavLink>
        </div>
        <div className="lg:block">
          <NavList />
        </div>

        {/*
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
        */}
      </div>
      <Collapse open={openNav}>
        <NavList />
      </Collapse>
    </Navbar>
  );
};

export default NavbarSimple;
